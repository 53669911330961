// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import messages from './lang'
import en from './lang/en'
import fr from './lang/fr'
import es from './lang/es'
Vue.use(VueI18n);
 
// 根据域名设置默认语言

const domainToLanguage = {
  'en': 'en',
  'fr': 'fr',
  'es': 'es',
};
const messages ={
  en,
  fr,
  es
}
const currentDomain = window.location.pathname.split('/')[1];

const defaultLanguage = domainToLanguage[currentDomain] || 'en';
 
const i18n = new VueI18n({
  locale: defaultLanguage, // 设置默认语言
  fallbackLocale: 'en', // 后备语言
  messages, // 设置语言环境信息
});
export default i18n;