import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMeta from 'vue-meta'
import i18n from './i18n'

Vue.use(VueMeta)
Vue.config.productionTip = false
import Bridge from './utils/JSbridge.js'
Vue.prototype.$bridge = Bridge

const domainToLanguage = {
  'en': 'en',
  'fr': 'fr',
  'es': 'es',
};
const currentDomain = window.location.pathname.split('/')[1];
const defaultLanguage = domainToLanguage[currentDomain] || 'en';
Vue.prototype.$curLang = defaultLanguage


import  {
  Form, FormItem, Input, Radio, RadioGroup, Rate, Select,
  Option, Popconfirm, Card, Button, Popover, InputNumber,
  Row, Col, Drawer, Pagination, Collapse, CollapseItem,
  Dialog, Divider, Slider,
  Message, DatePicker,Tooltip ,Checkbox,CheckboxGroup,Table,TableColumn,Image,Tabs,TabPane,Switch,Loading 
} from 'element-ui'; // 引入element组件
// import 'element-ui/lib/theme-chalk/index.css'; // 引入element样式



const components = [
  Rate, Radio, RadioGroup, Form, FormItem, Input,
  Select, Option, Popconfirm, Card, Button, Popover,
  InputNumber, Row, Col, Drawer, Pagination, Collapse, CollapseItem,
  Dialog, Divider, Slider, Message, DatePicker,Tooltip,Checkbox,CheckboxGroup,Table,TableColumn,Image,Tabs,TabPane,Switch 
]
components.forEach(item=>{
  Vue.component(item.name,item)
})
// Vue.use(ElementUI)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this
  }
}).$mount('#app')
