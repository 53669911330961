export  default {
  //header
  headTop:'NUEVO CLIENTE {symbol} DE DESCUENTO',
  registNow:'Registrarse ahora',
  liveChart:'Live Chat',
  liveChartInfo:'Si tiene alguna duda antes de comprar, póngase en contacto con nuestros operadores en línea para obtener más información.',
  creatTickets:'Crear un cupón',
  creatTicketsInfo:'Si necesita ayuda con asuntos relacionadas con la postventa, envíe un ticket.',
  currencyInfo:'¡Enviamos nuestros pedidos a todo el mundo! Si desea ver los precios en una moneda diferente, cambie la tienda.',
  billedIn:'Su pedido se facturará en:',
  trackMyOrder:'Rastrea mi pedido',
  orderNum:'Número de pedido',
  account: "Cuenta",
  orders:'Pedidos',
  search:'Búsqueda',
  wishlist:'Lista de artículos deseados',
  changeCurr: 'Cambiar moneda',
  hotSearch:'Búsquedas popular',
  occasion:'Ocasión',
  trendPro:'Productos de tendencia',
  prom:'Baile de graduación',
  homecoming:'Homecoming',
  evening:'NOCHE',
  bridesmaids:'Dama de honor',
  wedding:'BODA',
  lang:"Idioma",
  country:"País",
  currency:"Moneda",
  couRegion:"País/Región",
  english:'Inglés',
  spanish:'Español',
  france:'Francés',
  store:'Tienda',
  //home
  shopFullCo:'COMPRAR COLECCIÓN COMPLETA',
  top: 'Top',
  categories: 'Categorías',
  slimProm:'BAILE DE GRADUACIÓN DE AJUSTE DELGADO',
  alineProm:'BAILE DE GRADUACIÓN DE LÍNEA A',
  bridesmaid: 'Dama de honor',
  moms: 'Mamás',
  flowerGirl: 'Pajecita',
  headpieces: 'Tocados',
  swatches:'Muestra de fábrica',
  motherOf:'Vestidos de madrina 2024',
  motherInfo:'Puedes crear la mezcla perfecta y jugar con los tejidos para conseguir un ambiente de ensueño.',
  new:'Nuevo',
  arrivals:'Llegado',
  arrivalsInfo:"Todo está en los detalles. ¿Cuál es tu estilo?",
  viewAllDress:'Ver todos los vestidos',
  bridesmaidsMix:'Dama de honor Mix & Match',
  bridesmaidsMixInfo:'Nuestros colores están totalmente surtidos para que mezclar y combinar resulte muy fácil.',
  inspiration:'BUSCANDO INSPIRACIÓN',
  accessoires:'Accesorios',
  accessoiresInfo:'Accesorios ultra femeninos para tu gran día.',
  weddShoes:'Zapatos de boda',
  shoesInfo:'Comodidad y elegancia,Detalles y estilo',
  fabrics:'Muestras & Fábricas',
  fabricsInfo:'Te ayudará a aprender cómo queda un color concreto',
  gallery1:'Estilo',
  gallery2:'Galería',
  galleryInfo:'Clientes de STACEES de todo el mundo comparten con nosotros sus momentos de estilo personal.',
  galleryInfo1:'Los clientes de STACEES comparten momentos maravillosos con nosotros.',
  product:'VER PRODUCTO',
  loadMore: 'CARGAR MÁS',
  viewMore:'VER MÁS VIEW MORE',
  viewLess:'VER MENOS VIEW LESS',
  chat:'Chatear con nosotros',
  chatInfo:'Nuestro equipo de atención al cliente está disponible y puede responder cualquier pregunta.',
  customiz:'Personalización',
  customizInfo:'Ofrecemos servicios personalizados, talla personalizada o diseño exclusivo para su vestido.',
  affordable:'Lujo asequible',
  affordableInfo:'Puede estar seguro de que su inversión en cualquiera de nuestros vestidos siempre merecerá la pena.',
  fastShip:'Envío rápido',
  fastShipInfo:'Tenemos más de 100 vestidos que se envían en 48 horas.',
  //footer
  about: 'acerca de',
  aboutSta:'Acerca de STACEES',
  shipping:'Información de envío',
  searches:'Búsquedas',
  blog:'Blog de STACEES',
  help:'Ayuda',
  faq:'F.A.Q.',
  return:'Devoluciones y reembolso',
  track:'Rastrear de pedido',
  tailor:'Proyecto a medida',
  customer:'atención al cliente',
  SizeGuide:'Guía de tamaños',
  colourGuide:'Guía de color',
  contactUs:'Contáctenos',
  paymentMethod:'Opciones de pago',
  studentDiscount: 'Descuento para estudiantes y trabajadores clave',
  ShopByCate:'Comprar por categoría',
  promDress: 'Vestidos de graduación',
  homeComDress: 'Vestidos para homecoming',
  weddingDress:'Vestidos de novia',
  bridesmaidDress:'Vestidos de dama de honor',
  motherBride:'Vestidos de madrina',
  weddingGuest:'Vestidos de invitadas de boda',
  flowerGirlDress:'Vestidos de pajecitas',
  reserved:'Reservados todos los derechos.',
  conditions: 'Términos y condiciones',
  privacyPolicy:'Política de Privacidad',
  aboutUs:'Sobre Nosotras',
  emailAddr:'Correo Electrónico',
  enterEmailAddr:'Introduzca la dirección de email',
  signTip:'Regístrese para recibir correos electrónicos y obtenga {symbol} de descuento en cualquier pedido.',
  signTip1:'REGÍSTRESE A NUESTROS BOLETINES',
  copyRight:'Copyright',
  signTit:'Introduzca su correo electrónico para suscribirse al boletín informativo',
  subscribInfo:'¡Gracias por su suscripción! Disfrute comprando en nuestra tienda.',
  prefrence:'Elige tu estilo favorito.',
  //list
  home: 'Inicio',
  ships48: 'ENVÍO EN 48 HORAS',
  sort: 'Ordenar',
  popular: 'Más popular',
  recommend: 'Recomendar',
  newArrival: 'Recién llegado',
  lPrice: 'Precio bajo',
  hPrice: 'Precio alto',
  filter: 'Filtrar',
  filterBy: 'Filtrar por',
  price: 'Precio',
  clearAll: 'Borrar todo',
  off: 'DTO.',
  apply: 'APLICAR',
  Color: 'Color',
  Colors: 'Colores',
  '2024 Prom Dresses': `Vestidos de graduación {name}`,
  'Long Prom Dresses': 'Vestidos de graduación largos',
  'Ball Gown Prom Dresses': 'Vestidos de graduación corte de baile ',
  'Sexy Prom Dresses': 'Vestidos de graduación sexys',
  'Lace Prom Dresses': 'Vestidos de graduación de encaje',
  'Off the Shoulder Prom Dresses': 'Vestidos de graduación con hombros descubiertos',
  'Two Piece Prom Dresses': 'Vestidos de graduación de dos piezas',
  '2024 Wedding Dresses': `Vestidos de novia {name}`,
  'Beach Wedding Dresses': 'Vestidos de novia de playa',
  'Lace Wedding Dresses': 'Vestidos de novia de encaje ',
  'Wedding Veils': 'Velos de novia',
  'Evening Dresses': 'Vestidos de noche',
  '2024 Evening Dresses': `Vestidos de noche {name}`,
  'Long Evening Dresses': 'Vestidos de noche largos',
  'Homecoming Dresses 2024': `Vestidos para homecoming {name}`,
  'Cocktail Dresses': 'Vestidos de cóctel',
  'Wedding Pumps': 'Zapatos de boda',
  "Women's Shoes": "Zapatos de mujer",
  "Girls' Shoes": "Zapatos de niña",
  "Dance Shoes": "Zapatos de danza",
  nextPage:'Página<br/>siguiente',
  searchResFor: 'Resultados de búsqueda para',
  sortBy: 'Ordenar por',
  searchResult:'Lo sentimos, no hay ningún resultado para su búsqueda.',
  products: "Productos",
  proNotFound:'Producto no encontrado',
  searchFor: 'Buscar artículos',
  'Short Prom Dresses':'Vestidos de graduación cortos',
  'Sparkly Prom Dresses':'Vestidos de graduación brillantes',
  'Mermaid Prom Dresses':'Vestidos de graduación de sirena',
  'Formal & Evening Dresses':'Vestidos formales y de noche',
  'A-Line Wedding Dresses':'Vestidos de novia de línea A',
  'Mermaid Wedding Dresses':'Vestidos de novia de sirena',
  'Ball-Gown Wedding Dresses':'Vestidos de novia',
  'Plus Size Wedding Dresses':'Vestidos de novia tallas grandes',
  'Reception Dresses':'Vestidos de recepción',
  'Colored Wedding Dresses':'Vestidos de novia de colores',
  'Veils':'Velos',
  'Long Mother of the Bride Dresses':'Vestidos de madrina largos',
  'Short Mother of the Bride Dresses':'Vestidos de madrina cortos ',
  'Tea Length Mother of the Bride Dresses':'Vestidos de madrina hasta la tibia',
  'Plus Size Mother of the Bride Dresses':'Vestidos de madrina talla grande',
  'Mother of the Bride Dresses With Jackets & Wraps':'Vestidos de madrina con chaquetas y chales',
  'Mother of the Bride Dresses With Sleeves':'Vestidos de madrina con mangas',
  'Long Bridesmaid Dresses':'Vestidos de dama de honor largos',
  'High-Low Bridesmaid Dresses':'Vestidos de dama de honor altos-bajos',
  'Short Bridesmaid Dresses':'Vestidos de dama de honor cortos',
  'Tea-Length Bridesmaid Dresses':'Vestidos de dama de honor hasta la tibia',
  'Mix & Match Bridesmaid Dresses':'Vestidos de dama de honor Mix & Match',
  'Junior Bridesmaid Dresses':'Vestidos de damita de honor',
  'Fabric Swatch':'Muestras de fábrica',
  girlsDress:'Vestidos de pajecitas',
  partyShoes:'zapatos de fiesta',
  jacketsWraps:'chaquetas y chales',
  jelwery:'joyería del fiesta de boda',
  promShoes:'zapatos de graduación',
  fashionJelwery:'joyería de moda',
  //product
  ships48s:'Enviar en 48 horas',
  reviews: 'Comentarios',
  descript: 'DESCRIPCIÓN',
  details: 'DETALLES',
  modelInfo: 'INFORMACIÓN DEL MODELO',
  shipRetrun: 'ENVÍO Y DEVOLUCIÓN',
  proTime: 'Tiempo de producción',
  proTimeInfo: 'El plazo de producción estimado es de 2 semanas antes del envío de los artículos. También se puede solicitar una producción urgente, que está disponible por una tarifa adicional no reembolsable.',
  shipTimeSt: 'Tiempo de envío-Envío estándar',
  shipTimeInfo: 'El plazo de envío es de 3-10 días, en función de la ubicación y el método de envío. Se proporcionará una opción de tiempo de envío en la caja. ',
  seeMore: 'Ver más',
  authentic: '100% <span>auténtico</span>',
  cusSizem: 'Medida <span>personalizada</span>',
  freeGarm: 'BOLSA <span>DE ROPA </span><span>GRATIS</span>',
  highQam: 'ALTA <span>CALIDAD</span>',
  highQa: 'ALTA CALIDAD',
  recently:'VISTOS RECIENTEMENTE',
  easyRet:'DEVOLUCIONES FÁCILES',
  proInfo1: 'Diseñado por STACEES, 100% como en la foto.',
  proInfo2: 'Tamaño personalizada o diseño exclusivo para tu vestido.',
  proInfo3: 'Te ofrecemos una bolsa de ropa gratis cuando compras este vestido.',
  proInfo4: 'Nuestros vestidos son a la vez elegantes y cómodos.',
  proInfo5:'Plazo de devolución de 14 días',
  retPolicy: 'Política de devoluciones',
  retPolicyInfo1: 'Las devoluciones sin preocupaciones están disponibles para tamaños estándar en un plazo de 14 días. Los tamaños personalizados son venta final.',
  retPolicyInfo2: ' Asegúrate de que estén en su estado original: sin usar, sin lavar, sin modificaciones , sin daños, limpios, sin pelusa ni pelo y con las etiquetas intactas y pegadas.',
  allColors:'Todos los colores',
  inStockCol:'Colores en stock',
  colorChart: 'Tabla de colores',
  "As Picture": "Como en la foto",
  size: 'Tamaño',
  pleaseSel: 'Por favor seleccione',
  customSize: 'Medida personalizada',
  thisReq: 'Este es un campo obligatorio.',
  sizeGuide:'Guía de tallas',
  sizeChart: 'Tabla de tallas',
  toMeasure: 'Cómo medir',
  custom: 'Personalizado',
  inch: 'Pulgada',
  inches: 'Pulgadas',
  centimeters: 'Centímetros',
  orderSwat:'Pedir muestras',
  aviala: 'Disponibilidad',
  inStock: 'En stock',
  proAvailable: 'Producción urgente disponible',
  additional: 'Requisitos adicionales',
  additionalInfo: 'Instrucciones especiales sobre la personalización o manipulación (opcional)',
  addBag: 'AÑADIR A LA CESTA',
  orderToday: 'Ordenar hoy, ',
  getIt: 'consíguelo',
  tailTime: 'Plazo de confección',
  shipTime: 'Tiempo de envío',
  processTime: 'Tiempo de procesamiento',
  day: 'Días',
  orderTodyInfo: 'Este vestido es hecho a pedido. Así sea que seleccione una talla estándar o personalizada, nuestros costureros elaboran cada vestido por pedido.',
  completeLook: 'COMPLETA EL LOOK',
  toCart: 'AÑADIR A LA CESTA',
  viewFull: 'Ver todos los detalles',
  sizeOrder: 'TALLA PEDIDA',
  proLove: "CREEMOS QUE TE ENCANTARÁ",
  cusReview: 'COMENTARIOS DE CLIENTES',
  bestSal: 'los más vendidos',
  shareWith: 'Compartir con',
  customInfo1: '¿Buscas un ajuste perfecto? Para un adicional ',
  customInfo2: ', Nuestros sastres utilizarán sus medidas exactas para crear un vestido hecho a medida para usted.',
  passwd6Char: 'Por favor introduzca 6 o más caracteres.',
  passwdConsist: '¡Las dos contraseñas ingresadas deben ser consistentes!',
  verfied: 'Comprador verificado',
  allPhoto: 'Ver todas las fotos de los clientes',
  overFit: 'Generalmente ajustado',
  rating: 'Calificación',
  'Small': 'Pequeño',
  'Slightly Small': 'Ligeramente pequeño',
  'Right': 'Right',
  'Slightly Large': 'Ligeramente grande',
  'Large': 'Grande',
  overallRate: 'Calificación general',
  customPhoto: 'Fotos de clientes',
  backPro: 'Volver a la página del producto',
  basedOn: 'Basado en',
  shareLook: 'Comparte tu look para consigue',
  shareDiscount: 'de descuento',
  sharePhoto:'Compartir foto/video',
  allReviews:'Todos los comentarios',
  writeReview: 'Deje su comentario',
  fit: 'En forma',
  viewAll: 'Ver Todos',
  photos:'Fotos',
  end: 'Fin',
  share: 'Compartir',
  qty: 'Qty',
  waistInfo: 'Encuentre la cintura natural, Esta es la parte más estrecha de la cintura, Normalmente alrededor de 1 pulgada(aprox. 2,5 cm)por encima del ombligo,Mantenga la cinta ligeramente suelta para permitir el espacio para respirar.',
  bustInfo: '¡ No se refiere a la talla de sujetador ! Utilice un sujetador sin relleno (el vestido tendrá un sujetador incorporado), Suelte los brazos a los lados, Tire de la cinta a través de la parte más completa del busto.',
  floorInfo: 'Póngase de pie con los pies  juntos, Mida con los pies descalzos,Empieza en el hueco entre las clavículas y tira de la cinta hacia el suelo. ',
  hipInfo: 'Encuentre la parte más ancha de las cadera, Normalmente alrededor de 7 pulgadas  (aprox. 18cm) por debajo de la cintura natural, La cinta debe rozar alrededor del trasero.',
  extraInfo: 'Para vestidos hasta el suelo en talla personalizada¡Elige la opción de longitud extra para adaptarse a la altura de tus tacones! es decir, para tacones de 2 pulgadas (5 cm),Seleccione "+2 pulgadas" (5 cm). Para medidas planas, ingrese "+0".',
  hemInfo:'Póngase de pie con los pies descalzos juntos.Mida desde el centro de la clavícula hasta donde debe llagar el dobladillo delantero del vestido.',
  heightInfo: 'Póngase de pie con los pies  juntos,Mida con los pies descalzos, Empieza en la parte superior de la cabeza y tira de la cinta en línea recta hasta el suelo.',
  armholeInfo: 'Para tomar la medida de su sisa, debe envolver la cinta métrica sobre la parte superior de su hombro y alrededor de debajo de su axila.',
  armText1: "Esta es una medida alrededor de la parte más ancha de la parte superior del brazo.",
  descripts:'Descripciones',
  processReturn:'Tiempo de procesamiento y devoluciones',
  shipAddit:'El tiempo de envío demora entre 1 y 10 días hábiles adicionales, según la ubicación. El tiempo de envío se proporcionará al momento del pago.',
  returns:'Devoluciones',
  requestReturn:'Solicitar devolución dentro de los 14 días siguientes a la recepción.',
  image:'Imagen',
  max5Image:'Imágenes de 5 MB como máximo.',
  confirm:'Confirmar',
  selectSize:'Por favor seleccione talla.',
  copyUrl:'Copiar URL',
  oftenBought:'Comprado a menudo con',
  failed:'FALLIDO',
  //sizechart
  Bust: 'Busto',
  Waist: 'Cintura',
  Hips: 'Caderas',
  floor: 'Desde el cuello hasta el suelo',
  extra: 'Extra Length',
  htoHem: 'Desde el hueco hasta el dobladillo',
  toHem: 'Desde el hueco hasta el dobladillo(frente)',
  bareFoot: 'Altura(Pie descalzo)',
  armhole: 'Sisa', 
  armWidth: 'Ancho del brazo',
  footLen: 'Longitud del pie',
  toAnkle: 'Desde el hueco hasta el tobillo',
  toTea: 'Desde el hueco hasta el té',
  toKnee: 'Desde el hueco hasta la rodilla',
  ShoulderWidth:'Ancho de hombros traseros',
  sleeveLen:'Longitud de la manga',
  armscye:'brazo',
  armCir:'Circunferencia del brazo',
  child:'Niña',
  //view bag
  viewBag: 'VER CESTA',
  checkout: 'Tramitar pedido',
  shopBag: 'Cesta',
  bag:'Cesta',
  summary: 'Resumen de Pedido',
  subtotal: 'Subtotal',
  total: 'Total',
  goToCart:'IR AL CARRITO',
  proceedCheck:'PASAR POR LA CAJA',
  item: 'artículo',
  items: 'artículos',
  secureCheck: 'TRAMITAR PEDIDO SEGURO ',
  yourDiscount: 'Su código de promoción o descuento :',
  coupon: ' Cupón',
  couponCode: 'Código de cupón',
  useCoupon: 'Usar código de cupón',
  couponEnter: 'Por favor ingrese el código de cupón.',
  couponSuccess: '¡Le felicitamos! Usted tiene un cupón para ',
  couponError: 'Lo sentimos, ese código de cupón no válido.',
  cancel: 'Cancelar',
  weAccept: 'Aceptamos',
  safe: 'Seguridad y protección',
  safeInfo: 'Su información está siempre protegida.',
  saveWish: 'Guardar para lista de artículos deseados',
  removeBag: '¿Estás seguro de eliminar este artículo de tu cesta?',
  saveWishSure: '¿Estás seguro de guardar este artículo en tu lista de artículos deseados?',
  yes: 'Sí',
  bagTit: 'Cesta en STACEES',
  wishListSucc:'El artículo ha sido añadido a tu lista de artículos deseados.',
  youLove:"¡TE ENCANTARÁN ESTOS ARTÍCULOS!",
  charMax:'Caracteres máximos',
  bagEmpty: 'Su cesta está vacío',
  continueShopping: 'Continuar la compra',
  myBag:'Mi cesta',
  itNotHave:"Pero no tiene por qué ser...",
  youMayAlso:'También te puede gustar',
  remove:'Eliminar',
  //checkout
  placeOrder: 'HACER UN PEDIDO',
  pay: 'PAGAR',
  orderComp: 'PEDIDO COMPLETADO',
  contact: 'Información de contacto',
  guestSign: 'Tramitar pedido como invitado o ',
  signIn: ' Identificarse',
  email: 'Correo electrónico',
  firstName: 'Nombre',
  lastName: 'Apellido',
  address: 'Dirección',
  apartment: 'Apartamento, edificio,etc. (Opcional)',
  countryRegion:'Reino Unido',
  region: 'Estado/Provincia/Región(Opcional)',
  regionSel: 'Estado/Provincia/Región',
  city: 'Ciudad',
  postCode: 'Código postal/ZIP',
  gb: 'GB',
  phone: 'Teléfono',
  creatAcc: 'Crear cuenta (opcional)',
  save: 'GUARDAR',
  setDelivAddr: 'Establecer como dirección de envío por defecto',
  emailTip: "Le enviaremos la <br>confirmación de su pedido aquí.",
  phoneTip: "En caso de que necesitemos comunicarnos con<br> usted acerca de su pedido.",
  passwd: 'Contraseña Password',
  confirmPasswd: 'Confirmar la contraseña',
  addNewAddr: 'AÑADIR UNA NUEVA DIRECCIÓN',
  emailRequire: 'La dirección de correo electrónico es requerida.',
  enterEmail: 'Por favor, introduzca correo electrónico.',
  validEmail: 'Por favor, introduzca correo electrónico válida.',
  passwdRequire: 'Se requiere contraseña.',
  minChar: 'mínima de 6 caracteres',
  confirmPassRequire: 'Es necesario confirmar la contraseña.',
  passNoMatch: 'Contraseña no coincide',
  firstNameVerify: 'Tu Nombre debe contener por lo menos 2 caracteres.',
  lastNameVerify: 'Tu Apellido debe contener por lo menos 2 caracteres.',
  shippAddrVerify: 'La longitud mínima de la dirección de envío es de 5 caracteres.',
  countryVerify: 'El país es requerido.',
  cityVerify: 'La longitud mínima del nombre de ciudad es 3 caracteres.',
  regionVerify:' Estado/Provincia/Región es requerido.',
  ziPostVerify: 'Código postal/ZIP es requerido.',
  phone6Verify: 'Tu número de teléfono debe contener por lo menos 6 dígitos.',
  passwd6Verify: 'Tu contraseña debe contener por lo menos 6 dígitos.',
  shipMethod: 'Métodos de envío',
  shipMd: 'Método de envío',
  standShip: 'Envío estándar',
  free: 'Gratis',
  estDelive: 'Entrega prevista',
  expShip: 'Envíos expeditos',
  duty: 'Seguro de aranceles',
  shipInsure: 'Seguro de envío',
  dutyTip: "Si compra un seguro de aranceles e impuestos y a su paquete se le cobran aranceles e impuestos adicionales al momento de la entrega, comuníquese con nuestro servicio de atención al cliente y le compensaremos los aranceles e impuestos que haya pagado.",
  shipTip: "El seguro de envío ofrece protección y seguridad premium para sus artículos valiosos durante el envío internacional. Reenviaremos su paquete de inmediato sin cargo adicional si se informa que se perdió o se dañó.",
  editBag: 'Editar la cesta',
  shipFee: 'Tarifa de envío',
  picture: "Foto",
  edit:'Editar',
  delete:'Borrar',
  delAddress: '¿Seguro que desea borrar esta dirección?',
  saveShipp: 'Por favor, guarde tu dirección de envío.',
  sizeDetail: 'Detalles de tamaño',
  addrBook: 'Directorio',
  default1: 'Por defecto',
  selected:'Seleccionado',
  credit: 'Tarjetas de Crédito o Débito.',
  creditNote: 'Nota : Por motivos de seguridad, no guardaremos ninguno de los datos de su tarjeta de crédito.',
  paymentTrouble:'¿Tiene problemas con <br> el pago?',
  paymentText:'Puede contactarnos para obtener asistencia con el pago a través de :',
  openWait:'Apertura, por favor espere...',
  failedPurchase:'¡Compra fallida! Por favor complete el pago nuevamente.',
  eml: 'Correo electrónico',
  //login
  signRegister: 'IDENTIFICARSE/REGISTRARSE',
  register: 'REGISTRARSE',
  newTo: 'NUEVO EN STACEES',
  join: 'UNIRSE',
  now: 'NOW',
  forgetPasswd: 'Ha olvidado contraseña Forgot password',
  forgottenPasswd: 'Contraseña olvidada Forgotten password',
  or: 'o',
  signFackbook: 'Iniciar sesión con Facebook',
  signGoogle: 'Iniciar sesión con Google',
  iAccept: 'Acepto',
  signFor: 'Regístrese para obtener un cupón de <span>{symbol}</span> en su cuenta.',
  accountPrivacy: 'Al crear una cuenta, acepta la <a class="udline" href="{hrefPrivarcy}" rel="external nofollow">política de privacidad</a> de Stacees.com',
  orContinue:'o seguir con',
  bySignIn:'Al iniciar sesión, acepta nuestra <a href="{hrefPrivarcy}" rel="external nofollow">política de privacidad</a> <br/>y  <a href="{hrefTerms}" rel="external nofollow">términos de servicio</a>.',
  clickJoinNow: `Al hacer clic en "Unirse ahora", acepta los <a href="{hrefTerms}" rel="external nofollow">términos y condiciones</a> y la <a href="{hrefPrivarcy}" rel="external nofollow">política de privacidad</a> de STACEES.`,
  //forgot
  veriCode: 'Código Verificación',
  codeReq: 'Se necesita código.',
  codeVerify: 'Por favor, introduzca el código Please enter Code',
  codeError: 'Código error Code is error',
  send: 'ENVIAR SEND',
  forgotTxt1: "¿Cómo restablecer la contraseña?",
  forgotTxt2: "Escriba su dirección de correo electrónico de registro en la casilla de arriba y recibirá un correo con instrucciones para cambiar su contraseña.",
  forgotTxt3: "Escriba su dirección de correo electrónico",
  forgotTxt4: "Compruebe su correo electrónico",
  forgotTxt5: "Haga clic en el enlace para restablecer la contraseña",
  forgotTxt6: "Iniciar sesión con la nueva contraseña",
  forgotTxt7: "Se ha enviado un enlace para restablecer su contraseña a",
  forgotTxt8: "Los correeos pueden sufrir un retraso de unos minutos.",
  forgotTxt9: "Si no ha recibido un correo，compruebe sus carpetas de correo no deseado.",
  forgotTxt10: "Si sigues sin recibir el correo después de solicitar el restablecimiento de la contraseña, espera 24 horas y ",
  forgotTxt11: "vuelve a intentarlo",
  forgotTxt12:'Introduce tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.Por seguridad, los enlaces de inicio de sesión son válidos por 30 minutos.',
  resetPass:'Restablecer contraseña',
  createNewPass:'CREAR UNA NUEVA CONTRASEÑA',
  newPassInfo:'Gracias por volver. Introduzca los detalles de su nueva contraseña a continuación.',
  newPassTxt:"Su contraseña ha sido modificada.Inicie sesión nuevamente y estará listo para comenzar.",
  newPassRequire: 'Se requiere una nueva contraseña.',
  passMin6:'La longitud de la contraseña debe tener un mínimo de 6 caracteres.',
  oldPassRequire: 'Se requiere la contraseña antigua.',
  passAgain:'Por favor, introduzca su contraseña nuevamente.',
  submit: 'ENVIAR',
  back:'Atrás',
  //member
  welcomeTo:'BIENVENIDO A',
  yourAcct: 'Tu cuenta',
  hi: 'Hola',
  myOrders: 'Mis pedidos',
  myCoupons: 'Mis cupones',
  myReviews: 'Mis comentarios',
  myWishlist: 'Mí lista de artículos deseados',
  myTickets: 'Consultas Postventa',
  myProfile: 'Mi perfil',
  changePassword: 'Cambiar contraseña',
  signOut: 'cerrar sesíon',
  orderEmpty: 'SU PEDIDO ESTÁ VACÍO',
  orderNo: 'El número de pedido',
  date:'FECHA',
  status: 'Estado',
  open: 'Abierto',
  pending: 'Pendiente',
  closed: 'Cerrado',
  processing: 'Procesamiento',
  canceled:'Cancelado',
  shipped: 'Enviado',
  completed: 'Completado',
  awaitPaym: 'Esperando pago',
  viewDetail: 'Ver detalles',
  reorder: 'PEDIR DE NUEVO',
  ticketServ: 'Servicio de entradas',
  payNow:'PAGA AHORA',
  cancelOrder:'¿Estás seguro de que deseas cancelar el pedido?',
  shipAddr: 'Dirección de envío',
  billAddr: 'Dirección de facturación',
  delivery: 'OPCIONES DE ENTREGA',
  paymentMeth: 'Opciones de pago',
  subTot:'Subtotal',
  grandTotal:'Suma total',
  wishlistEmpty: '¡Su lista de deseos está vacía!',
  dateOfBirth: 'FECHA DE NACIMIENTO',
  gender: 'Sexo',
  ms: 'Sra',
  mr: 'Sr',
  mPrivacy: 'Privacidad',
  saveChanges: 'GUARDAR CAMBIOS',
  firNameRequire: 'El nombre es requerido.',
  lastNameRequire: 'El apellido es requerido.',
  optional: 'Opcional',
  oldPassword: 'Contraseña antigua',
  newPassword: 'Nueva contraseña',
  changed: 'CAMBIÓ',
  enterPass:'Por favor, introduzca su contraseña.',
  ticketId: 'ID de Consulta Postventa',
  type: 'Tipo',
  lastUpdate: 'Última actualización',
  changeSucc:'¡Cambiado con éxito!',
  saveSucc:'¡Guardado con éxito!',
  //ticket
  creatTicket: 'Crear un ticket',
  backOrder: 'Volver al pedido',
  selectTopic: 'Seleccione un tema',
  backTicket: 'Volver a la lista de consulta postventa',
  problemSoved: 'Su problema ha sido resuelto. Este  consulta postventa ha sido cerrado',
  addAPhoto: 'Añadir una foto',
  photoTip: 'Máximo <span class="cr-3c">3</span> fotos.Utilice únicamente archivos <span class="cr-3c">JPG/PNG/GIF/BMP. </span>El tamaño de cada foto individual no puede exceder <span class="cr-3c">8MB.</span>',
  conversReq: 'Se requiere conversación.',
  photoTipReq:'Máximo 3 fotos.Utilice únicamente archivos JPG/PNG/GIF/BMP. El tamaño de cada foto individual no puede exceder 8MB',
  ticketReceive:'Su consulta postventa ha sido recibido, le responderemos en breve.',
  produ:'Producto',
  orderTxt1: 'Los pedidos cancelados dentro de las 12 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 90% del precio de compra del producto.',
  orderTxt2: 'Los pedidos cancelados dentro de las 12 a 24 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 80% del precio de compra del producto.',
  orderTxt3: 'Los pedidos cancelados dentro de las 24 a 48 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial que consiste en el costo total de envío y el 50% del precio de compra del producto.',
  orderTxt4: 'Los pedidos cancelados más de 48 horas después de la confirmación del pago serán elegibles para un reembolso parcial únicamente del costo total de envío.',
  orderTxt5: 'Una vez que su pedido haya sido enviado, ya no podrá cancelarse.',
  phoneNum:'Número de teléfono',
  message: 'Mensaje',
  messageTip: 'Escribe tu mensaje con detalle en 5-1000 caracteres ...',
  messageReq: 'El mensaje es requerido.',
  photoCode:'Por favor, introduzca el código de verificación que aparece en la imagen : *',
  ticketBtn:'Enviar Consultas Postventa ',
  topicReq: 'El tema es requerido.',
  yourNameRequ: 'Su nombre es requerido.',
  codeRequ:'Se requiere código de verificación.',
  verCodeErr:'El código de verificación es un error',
  dispute:'Disputar',
  'Color deviation': "Desviación de color",
  "Size issues": "Problemas de tamaño",
  "Late for delivery": "Retraso en la entrega",
  "Differ from the pictures": "Diferencia con las fotos",
  "Quality deviation": "Desviación de calidad",
  "Customs&Tax": "Aduanas e impuestos",
  "Shipping problem": "Problema de envío",
  "Other problems": "Otros problemas",
  proCancel: 'Cancelación de productos',
  eventCancel: 'Evento fue cancelado',
  worryQuality: 'Preocuparse por la calidad',
  timeNoEnough: 'El tiempo no es suficiente',
  orderWrong: 'Pedí artículos incorrectos',
  otherShop: 'Encontraste un mejor servicio en otras tiendas.',
  orderModif: 'Modificación de pedido',
  style: 'Estilo',
  deliverDate: 'Fecha de entrega',
  others: 'otros',
  addConver:'Añadir una conversación',
  proInfo: 'Información del producto',
  loadFailed:'Error al cargar, por favor actualice la página.',
  //review
  viewReview:'Ver comentario',
  yourName: 'Su nombre',
  reviewTit: 'Título de su comentario',
  reviewTitTip: 'Dinos qué piensas',
  yourReview: 'Su comentario',
  yourReviewReq: 'Se requiere su comentario.',
  commentsTip1: 'CONSEJOS:\n¿Está satisfecho con su compra? \nPuede describir el producto en términos de calidad, estilo, aptitud, material, etc.',
  addPhoto: 'Añadir fotos (opcional)',
  toUpload: 'Haga clic aquí para subir',
  nameReq: 'El nombre es requerido.',
  reviewTitReq: 'El título de su comentario es requerido.',
  reviewReq:'Se requiere comentario.',
  imageMax5: '5 fotos máximo, 5 MB máximo por foto.',
  revHeadlineReq: 'El título del comentario es requerido.',
  ProCommentsReq: 'Se requieren comentarios sobre el producto.',
  ReviewsEmpty:'EL COMENTARIO ESTÁ VACÍO',
  //coupon
  allCoupons: 'Todos los cupones',
  availableCoupons: 'Cupones disponibles',
  expiredCoupons: 'Cupones caducados',
  expiredeEmpty:'Sus cupones caducados están vacíos.',
  availableEmpty:'Sus cupones disponibles están vacíos.',
  newRegistDiscount: 'Descuento para nuevos registrados',
  orderOver: 'pedido superior a',
  expires: 'Vence',
  copyAndUse: 'Copiar y utilizar en la página de tramitar pedido',
  code:'Código',
  copy: 'Copiar',
  successCopy: '¡Copia exitosa!',
  //Order tracking
  trackYourOrder: 'Rastrear su pedido',
  sigleOrder: 'Buscar un solo pedido',
  orderInfo: 'Introduzca su número de pedido y su correo electrónico para ver los detalles de su pedido.',
  orderQues: '¿Dónde puedo encontrar esto?',
  orderTrackInfo:'Nuestro número de pedido está incluido en el correo electrónico que recibió cuando realizó su pedido. Si ya no tiene este correo electrónico,<a class="cr-bd" href="{hrefContact}">comuníquese con nosotros</a>.',
  orderHistory: 'Ver historial de pedidos',
  signAccount: 'Acceda a su cuenta para ver todos sus pedidos.',
  orderRequire: 'El número de pedido es requerido.',
  trackText: 'Si ya tiene una cuenta, identifíquese para comprobar su pedido.',
  trackingOrder: 'Rastrear pedido',
  orderTrack: 'Rastrear pedido',
  trackOrderInfo1: 'Si desea consultar más información sobre su pedido, puede registrarse con la dirección de correo electrónico ',
  trackOrderInfo2: 'Si tienes más preguntas, puedes <a href="{url}">crear un ticket</a> en su cuenta.',
  trackShip: 'Seguir el envío',
  placed: 'Colocado',
  material: 'Preparación del material',
  inProduct: 'En producción',
  quality: 'Inspección de calidad',
  parcel:'paquete',
  shippedBy: 'Enviado por ',
  trackNumb: 'Número de rastreo:',
  trackinfo:'Este pedido ha sido enviado por transportista, se espera que la información de seguimiento se actualice dentro de 1-3 días.',
  paid:'Pagado',
  shipment:'Envío',
  take:'Llevar',
  dayArrive:'días para llegar',
  complete:'Completo',
  //gallery
  note: 'Nota :',
  sahreNote: '1 vídeo como máximo, 50 MB como máximo por vídeo,<br/>o 5 fotos máximo, 5 MB máximo por foto.',
  sahreNoteReq: '1 vídeo como máximo, 50 MB como máximo por vídeo,o 5 fotos máximo, 5 MB máximo por foto.',
  next: 'Siguiente',
  name:'Nombre',
  revHeadline: 'Título del comentario',
  ProComments: 'Comentarios sobre el producto',
  commentsTip: '(¿Está satisfecho con su compra? Puede describir el producto desde la calidad, el estilo, la forma física, el material, etc.)',
  shareSucTxt1: '¡GRACIAS ENCANTADOR!',
  shareSucTxt2: '¡Verificaremos dos veces la foto solo para asegurarnos de que todo esté bien!',
  shareSucTxt3: 'Su foto se publicará después de la aprobación.¡Gracias, cariño!',
  continueUpload: 'SEGUIR SUBIENDO',
  viewGall: 'VER GALERÍA',
  uploadVideoReq:'Por favor utilice únicamente de video MP4 o  imagen JPG/PNG/JPEG/BMP. No cargue imágenes y videos al mismo tiempo.',
  shareStory: 'Compartir su historia',
  all:'Todo',
  emptyRefresh:'¿Vacío? Por favor, actualice la página',
  shopLook: 'COMPRAR EL LOOK',
  photosReq:'Se requieren fotos.',
  //about
  about1:'¡BIENVENIDO A STACEES!',
  about2:'Gracias por visitar STACEES. En primer lugar, encontrará miles de productos a precios increíbles.',
  about3:'En STACEES, tenemos nuestra propia fábrica de vestidos de novia, con un equipo de diseño profesional y un proceso completo de producción y fabricación.',
  about4:'Establecida en 2012, STACEES es una tienda minorista en línea de vestidos de novia, vestidos para eventos especiales, vestidos de fiesta de bodas y accesorios. Los clientes pueden explorar una gran selección en línea y elegir sus vestidos favoritos con gran satisfacción. Nos comprometemos a brindarles a nuestros clientes productos y servicios de alta calidad.',
  about5:"Ofrecemos a nuestros clientes una amplia gama de vestidos de novia de alta calidad. Presentamos a nuestros clientes vestidos de novia, vestidos de dama de honor, vestidos de madrina, vestidos de noche y vestidos de fiesta con un estilo único, diseño a medida y un servicio atento.",
  about7:'Tenemos una cinta azul vestidos de los fabricantes en nuestro país y somos estrictos con el curso de la producción, paticular en minucias, incluso si un pequeño problema se produjo en la cremallera, vamos a volver y trabajar de nuevo, sin embargo, nunca sucedió.',
  about8:'Servicio',
  about9:'Nuestros representantes del servicio de atención al cliente están disponibles 7*24 y son profesionales especializados en resolver cualquier pregunta. No dude en ponerse en contacto con nosotros en cualquier momento.',
  about10:'Marca',
  about11:'STACEES es una marca líder en la fabricación de vestidos a medida en todo el mundo, ofrecemos los últimos y más populares vestidos y ofrecemos un servicio excelente a todos los clientes que nos eligen.Cada vestido se hace a medida y se ajusta perfectamente a su cuerpo si sus medidas son correctas. Nos dedicamos a producir los vestidos más hermosos con la máxima calidad, por lo que su satisfacción es nuestro mayor estímulo.',
  about12:'Gestión',
  about13:"Contamos con un destacado grupo de técnicos de gestión, que se encargarán de garantizar que todo el proceso de STACEES funcione correctamente y, además, cada línea de trabajo estará equipada con un funcionario de gestión en todo momento. Para garantizar la alta calidad de nuestros productos y servicios, incorporamos con éxito la tecnología de fabricación más avanzada a nivel internacional.",
  about14:'Para la satisfacción de nuestros clientes, ofrecemos un servicio de procesamiento urgente para los compradores que no disponen de tiempo. Para asegurarnos de que todos sus pedidos lleguen en perfectas condiciones, trabajamos con muchos servicios exprés oficiales, como DHL, UPS, Aramex, EMS, etc. Seleccionaremos el mejor servicio para usted según su ubicación y sus necesidades. Principio de nuestra empresa: garantía de calidad y supervivencia para una buena reputación para servir a nuestros clientes de todo corazón.',
  about15:'Alta calidad / Precios asequibles',
  about16:'Los vestidos son confeccionados con alta calidad por los mejores sastres y nos alegra que sea por eso que cada vez más clientes nos eligen. Todas las chicas merecen el servicio y la calidad de STACEES. Para ofrecer un precio más bajo y competitivo, no lo actualizaremos con frecuencia a menos que nuestra empresa haga algunos ajustes en algunos de los vestidos más vendidos. Todos pueden tener un código de cupón que puede ahorrar € 5 después de registrarse con éxito en nuestro sitio. No aceptamos. ',
  about17:'Si tiene más preguntas, póngase en contáctenos.<br />Por correo electrónico utilizando la dirección de correo de la página <a class="undline" href="{hrefContact}">Contáctenos</a>.',
  //shipping info
  shipInfo1:'Regiones de envío, métodos de envío, tiempo y gasto de envío e información adicional sobre el proceso de envío de STACEES ',
  shipInfo2:'Nuestros productos pueden entregarse en todo el mundo. Enviamos nuestros productos mediante los siguientes transportistas: UPS, DHL, TNT, FedEx, etc.El tiempo de envío varía en función de la distancia de envío. La eficacia de los transportistas locales también afecta al plazo de entrega. El tiempo de envío desde nuestras fábricas hasta su destino es de entre 2 y 15 días laborables. El tiempo exacto de envío depende de los estándares del transportista (UPS, DHL, TNT, FedEx, etc.) y del método de envío que elija. Por lo general, el tiempo y el coste de envío a diferentes zonas son los siguientes:',
  shipInfo3:'África',
  shipInfo4:'Países insulares en el océano',
  shipInfo5:'Reino Unido',
  shipInfo7:'Otros países de Europa',
  shipInfo8:'EE.UU. y Canadá',
  shipInfo9:'Otros países de Norteamérica',
  shipInfo10:'Otros países de Sudamérica',
  shipInfo11:'Australia',
  shipInfo12:'Otros países de Oceanía',
  shipInfo14:'Los días arriba indicados son todos laborables. Durante los días festivos importantes, el número de fletes del aeropuerto aumentará y el transporte se retrasará. Si necesita su pedido con urgencia, póngase en contacto con nosotros con antelación e infórmenos de la fecha que necesita. Lo organizaremos para usted lo antes posible.',
  shipInfo15:'TARIFA DE ENVÍO: Nuestros gastos de envío dependen del peso total de tu pedido y del país de destino de los vestidos.',
  shipInfo16:'Antes de enviar su pedido, el sitio STACEES debe procesar sus pedidos y pasar estrictas pruebas de control de calidad en todos sus proyectos. Por lo tanto, el plazo de entrega total es diferente del plazo de envío por separado',
  shipInfo17:'Producción y plazos de entrega',
  shipInfo18:'Tiempo total de entrega = Tiempo de procesamiento + Tiempo de envío',
  shipInfo19:'Si su pedido contiene varios artículos, se enviarán juntos.',
  shipInfo20:'En general, dependiendo de la complejidad de los vestidos.El tiempo de procesamiento difiere de una categoría a otra. Por favor refiérase a la siguiente tabla para un procesamiento más preciso para estimar mejor cuándo recibirá su pedido:',
  shipInfo23:'El tiempo de procesamiento específico depende de los artículos. El plazo de entrega de accesorios, muestras y algunos artículos depende de nuestro inventario y puede ser de 24 horas cuando tenemos existencias suficientes.',
  shipInfo24:'Procesaremos su pedido en un plazo de 24 horas e informaremos a nuestros sastres para que comiencen a confeccionar los vestidos para usted. Por lo tanto, si desea cancelar el pedido, comuníquese con nosotros en un plazo de 24 horas y le cobraremos la cancelación del pedido por más de 24 horas, ya que la tela utilizada en el vestido ha sido cortada. Para conocer la política de cancelación detallada, consulte nuestra página de <a href="{hrefOrderCan}">cancelación de pedidos</a>.',
  shipInfo26:'También ofrecemos un servicio acelerado para clientes que necesitan un vestido con urgencia. Puedes optar por el servicio de pedido urgente para algunos estilos. Puedes elegir la fecha de llegada en la opción de nuestra página de productos. Cuando elijas una fecha muy temprana, el sistema calculará automáticamente el costo adicional de la producción urgente para tu vestido y habrá un cartel visible en el precio. Sin embargo, si recibes el vestido en una fecha muy tardía, te daremos un descuento del 5%. Para tener tiempo suficiente para hacerte un vestido más ajustado y sofisticado, es mejor que hagas tu pedido con anticipación.',
  shipInfo27:'Además, si no recibe su pedido en el plazo elegido, le ofreceremos un reembolso limitado.',
  shipRegion: 'Regiones de envío',
  shipCost: 'Gastos de envío',
  from: 'Desde',
  shipInfo28:'Países de Europa',
  rushOrder: 'Pedido urgente',
  category: 'Categoría',
  specialOccasion: 'Vestidos para ocasiones especiales',
  motherTheBride: 'Vestidos de madrina',
  accessories: 'Accesorios',
  shoes: 'Zapatos',
  swatch: 'Muestras',
  shipp: 'Envío',
  //faq
  askQuest: 'Preguntas frecuentes',
  livechat: 'Livechat',
  payment: "Pago",
  faq1:"¿Puedo obtener más descuento en pedidos múltiples o al por mayor?",
  faq2:"Hemos hecho todo lo posible para fabricar la mayoría de los vestidos a un precio razonable y asequible. Para que cada niña tenga su estilo favorecedor en su evento, nos complace hacer un trato en pedidos al por mayor según la cantidad. Para obtener más detalles sobre los descuentos, comuníquese con nuestro asistente en línea o envíenos un correo electrónico.",
  faq3:"¿Puedo modificar el pedido?",
  faq4:"Para garantizar que cada pedido pueda entregarse a tiempo,siempre le enviaremos un correo electrónico de confirmación para reconfirmar el pedido y aún podrá informarnos sus requisitos para el pedido dentro de un tiempo determinado. Para garantizar que los pedidos se entreguen a tiempo, los pedidos se procesarán tras la confirmación o si no se envía ninguna consulta dentro de las 12 horas posteriores al envío del correo electrónico de confirmación. Por favor, comprenda que debido a tiempos, materiales y costos de personalización, será difícil o imposible realizar cambios en un pedido una vez que se haya procesado.",
  faq5:"¿Cómo puedo comprobar el estado del pedido?",
  faq6:'Una vez realizado el pedido, puede verificar el estado del mismo en <a href="{hrefOrder}">mi cuenta en</a> cualquier momento para saber en qué paso se encuentra el pedido. También puede enviarnos un correo electrónico o contactarnos en línea para obtener más detalles.', 
  faq7:"Quiero pagar, pero ¿por qué se rechaza el pago?",
  faq8:"Creemos que cada cliente disfrutará de una compra segura y conveniente aquí, ya que ofrecemos las dos opciones de pago más populares: PayPal y tarjeta de crédito o débito. Pero, por desgracia, si el pago es rechazado, no te preocupes, estamos aquí para ayudarte.Esto se debe a la restricción del sistema bancario, que tiene muchos factores implicados, como las tarjetas utilizadas en otro lugar, de uso frecuente recientemente, etc. Solo tendrá que utilizar otra tarjeta o llamar a su banco para levantar esa restricción y volver a intentarlo. ",
  faq9:"¿Cómo uso el cupón?",
  faq10:"Complete el código de cupón que se muestra en la página de pago y luego aplíquelo. No importa si tiene cupones registrados o cupones negociados con el asistente de ventas.",
  faq11:"¿Es seguro hacer el pago aquí?",
  faq12:"La seguridad de la información que nos envían nuestros clientes es una de nuestras mayores prioridades. Estamos verificados por el Sistema Internacional de Validación Bancaria y Paypal, supervisados bajo sus términos y política. Aquí tendrá la experiencia de compra más segura.",
  faq13:"¿Qué tipos de tarjetas se puede pagar?",
  faq14:"La mayoría de las tarjetas de crédito y débito son fáciles de pagar aquí.Pueden ser tarjetas visa, tarjetas máster, etc.",
  faq15:"¿Puedo modificar la dirección de envío?",
  faq16:"Puede modificar la dirección de envío antes de que el pedido pase a la fase de entrega. Puede haber gastos de envío adicionales y derechos de aduana, que dependen del destino y de su política local. Puede comprobarlo con nuestro asistente en línea o con el departamento local.",
  faq17:"¿Cuánto dura el tiempo de envío?",
  faq18:"Todos los pedidos que se realicen aquí se entregarán con un servicio seguro y puerta a puerta. Llegarán a su casa en aproximadamente 5 días después del envío.Por favor, comprenda que la fecha estimada de llegada puede no ser tan fiable como usted espera cuando hay vacaciones, tiempo horrible inesperado u otras cosas que puedan afectar al servicio de entrega.",
  faq19:"¿Y si se me pasa la entrega?",
  faq20:"Después de que falle el primer intento de entrega, podrá encontrar los contactos de su servicio exprés local en nuestro correo electrónico de entrega fallida y solicitarles que organicen otra entrega en el otro día que le parezca conveniente. Tenga en cuenta que si no puede comunicarse con usted o no está disponible durante varios intentos de entrega, puede haber un cargo adicional por la nueva entrega. En el caso de paquetes devueltos o destruidos por el servicio exprés debido a que el cliente no responde durante mucho tiempo, no se puede concluir que sea culpa nuestra.",
  faq21:"Devolución y reembolso",
  faq22:"¿Puedo devolver el vestido cuando lo reciba?",
  faq23:"Puedes devolver el vestido y obtener el reembolso si hay algún problema con el vestido que has recibido.",
  faq24:"Devolví el vestido, ¿cuándo puedo recibir el reembolso?",
  faq25:"Organizaremos el reembolso al recibir el vestido que devuelva. Por lo general, el reembolso se efectuará en un plazo de 2 a 5 días.",
  faq26:"¿Dónde debo devolver mi vestido?",
  faq27:"Debe comunicarse con nuestro equipo de servicio para obtener un formulario de devolución antes de devolver los artículos. Por favor, no lo devuelva a la dirección que aparece en el paquete.",
  faq28:`¿Puedo pedir varios colores y tamaños para el mismo estilo?`,
  faq29:`Podemos proporcionar vestidos y trajes de todos los tamaños y colores varios incluso tamaño personalizado.No te preocupes por las existencias, nuestras experimentadas costureras de salón pueden suministrarte un gran número de disfraces para tu gran fiesta. `,
  faq30:"¿Podemos modificar ligeramente el vestido?",
  faq31:"Si desea un vestido con personalidad, podemos ayudarle a modificarlo un poco, contáctenos en línea o envíenos un correo electrónico con sus ideas, haremos todo lo posible para satisfacer su solicitud.",
  faq32:"¿Cómo puede asegurarse de que el pedido de devolución es exactamente el mismo que los anteriores?",
  faq33:`Debido a que muchos pedidos especiales se hacen a mano, es inevitable que haya una ligera desviación de colores y detalles entre los pedidos devueltos, pero hay forma de resolverlo.  Es necesario que proporcione fotos de los vestidos que ha recibido, para que los pedidos puedan coincidir fácilmente.`,
  faq34:`¿Cómo puedo asegurarme de que la talla y el color me quedan perfectos?`,
  faq35:`Entendemos que cada vestido puede ser muy importante para ayudarte a destacar. Para tener un vestido que te quede perfecto, selecciona tu talla exactamente según la tabla de tallas o dinos tu talla exacta cuando hagas el pedido. El color puede ser ligeramente diferente debido al monitor, la luz de la fotografía y el entorno, te sugerimos que obtengas las muestras de fábrica en el sitio web para ayudarte a decidir.`,
  faq36:"¿Puedo tener un vestido que no esté en el sitio?",
  faq37:"Si tiene un diseño en mente o ve algunos vestidos fantásticos u otros recursos, háganoslo saber, simplemente muéstrenos las imágenes, las verificaremos con nuestros sastres expertos y le brindaremos una cotización dentro de las 12 horas.",
  faq38:"¿Cómo puedo restablecer mi contraseña?",
  faq39:"Solo tiene que seguir las instrucciones de Olvido de contraseña. Por motivos de seguridad, no podemos enviarle su contraseña por correo electrónico. Recibirá un enlace para restablecer la cuenta en su correo electrónico. Siga ese enlace para restablecer la contraseña. Si no recibe el correo electrónico, póngase en contacto con nosotros para que nuestro departamento de tecnología se lo restablezca manualmente.",
  faq40:"¿Tengo que ser miembro para comprar aquí?",
  faq41:`También puedes hacer pedidos aquí como invitado. Sin embargo, si tienes una cuenta, es más fácil hacer un seguimiento de tu pedido, guardar direcciones frecuentes y enviar tickets. Puedes crear tu cuenta <a href="{hrefOrder}">aquí</a> fácilmente en un minuto.`,
  faq42:"¿Cómo cambio la dirección de correo electrónico de la cuenta?",
  faq43:"Para garantizar la seguridad de su cuenta, no se le permite modificar la dirección de correo electrónico registrada, pero puede indicarnos la dirección que desea utilizar. La apuntamos y actualizaremos el estado del pedido con esa dirección.",
  faq44:"Si tengo alguna pregunta sobre mi pedido, ¿qué debo hacer?",
  faq45:"En su cuenta, puede comprobar el estado de su pedido en “Mi pedido”.Si tiene alguna duda sobre su pedido al recibirlo, puede enviarnos un ticket.Nuestro equipo de servicio le responderá en 24 horas.",
  //Return & Refund
  refundTit:'Devoluciones , reembolso y cambios',
  retRefund:'DEVOLUCIONES Y REEMBOLSOS FÁCILES',
  retRefundCont1:"Gracias por comprar en STACEES.",
  retRefundCont2:"Si no está completamente satisfecho con su pedido, estamos aquí para ayudarlo. Le reembolsaremos el importe íntegro de su compra, incluidos los gastos de envío de todos los artículos dañados, defectuosos o enviados por error. Si no está satisfecho con su compra, no dude en ponerse en contacto con nosotros lo antes posible para comenzar a procesar su devolución y reembolso.",
  retRefundCont3:"Condiciones de devolución",
  retRefundCont4:"Solo se pueden devolver las mercancías dentro de los 14 días siguientes a su recepción. Recomendamos a nuestros clientes que examinen el contenido de su envío en cuanto lo reciban. Una vez que esté seguro de que los artículos están dañados o han sido enviadoserróneamente, inicie lo antes posible el proceso de devolución o reembolso.",
  retRefundCont5:"Por favor, póngase en contacto con nuestro departamento de atención al cliente y solicite la devolución de su pedido. No devuelva el paquete directamente a la dirección que figura en nuestro sitio web o en el paquete, que no es nuestra dirección de devolución.",
  retRefundCont6:"Para poder optar a la devolución o el reembolso, el artículo debe estar en su estado original. No se aceptarán devoluciones de artículos usados, desgastados, lavados, alterados o dañados. Asegúrese de dejar las etiquetas originales en los artículos si desea devolverlos.",
  retRefundCont7:"Todos nuestros artículos se fabrican por encargo. Esto significa que no stock artículos adicionales en nuestra tienda.Una vez que realice su pedido, éste se fabricará específicamente para usted. Por lo tanto, no aceptaremos devoluciones de productos que se ajusten a su pedido.Nuestra política de devoluciones solo contempla daños o envíos erróneos de artículos.",
  retRefundCont8:"Cuando nos envíe su devolución, asegúrese de conservar el recibo de envío y la información de seguimiento. No nos hacemos responsables de los artículos devueltos que se envíen erróneamente, se dañen o se pierdan durante el envío.",
  retRefundCont9:"Cuando reciba un artículo que considere defectuoso,artículos dañados o mal enviados, tome imágenes del paquete original. Esto nos ayudará a acelerar el proceso de devolución, reembolso parcial o reembolso completo.",
  retRefundCont10:"Una vez que recibamos sus artículos devueltos, inspeccionaremos su pedido y le notificaremos que los hemos recibido.Una vez procesada y aprobada la devolución, iniciaremos el proceso de reembolso a través del método de pago original. Dependiendo de las políticas del emisor de su tarjeta, puede esperar recibir su abono en unos días.",
  retRefundCont11:"Para todos los pedidos de tamaño estándar/personalizado",
  retRefundCont12:"Para todos los pedidos de selección de estilo/tamaño",
  retRefundCont13:"Ajustes y compensar",
  retRefundCont14:"Muestras de fábrica, artículos personalizados, accesorios y pedidos urgentes.",
  retRefundCont15:"Para todos los artículos en venta final",
  retRefundCont16:"Artículos dañados, defectuosos o mal enviados",
  retRefundCont17:"Asuntos  de tamaño y ajuste",
  retRefundCont18:"Desviación de tamaño",
  retRefundCont19:"Desajuste de color",
  retRefundCont20:"Asuntos hechas a mano",
  retRefundCont21:"Política de Intercambios",
  retRefundCont22:"Cómo tramitar su devolución, reembolso o compensar",
  retRefundCont23:"Política de cancelación",
  retRefundCont24:"STACEES tramitará el reembolso íntegro, más los gastos de envío de todos los productos dañados, defectuosos o enviados erróneamente. Si no está satisfecho con su pedido, por favor tome imágenes del pedido original para que podamos procesar su reembolso.",
  retRefundCont25:"Si cambias de opinión sobre el pedido, por favor procesa la solicitud de devolución dentro de los 14 días siguientes a la recepción del pedido. Ten en cuenta que estos son días naturales, por lo que también se consideran los fines de semana. Por lo tanto, lo mejor para ti es que te pongas en contacto con nuestro departamento de atención al cliente lo antes posible para procesar tu devolución.",
  retRefundCont26:"Todos los artículos que no presenten daños deben devolverse en su estado original. No aceptamos la devolución de artículos desgastados, lavados, alterados o dañados. Asegúrese también de enviar todos los artículos devueltos con sus etiquetas originales. Es posible que no admitamos devoluciones de artículos que no cumplan estas condiciones o nuestra política de devoluciones.",
  retRefundCont27:"Una vez que hayamos recibido su solicitud de devolución y hayamos acusado recibo, tardaremos hasta 7 días laborables en procesar su devolución, cambio o reembolso. Tenga en cuenta que el tiempo que se tarda en recibir su reembolso depende de las políticas del emisor de su tarjeta o de su banco. Por lo general, la mayoría de los emisores de tarjetas tardan entre 7 y 14 días laborables en procesar un reembolso.",
  retRefundCont28:"Todos los pedidos se hacen según sus especificaciones. Esto significa, esencialmente, que una vez completado el pedido, será para usted y solo para usted. No podemos revender un pedido a medida a otro cliente. Por lo tanto, no procesaremos devoluciones ni reembolsos de pedidos hechos a medida a menos que haya un problema de calidad. Antes de realizar su pedido, asegúrese de revisar y confirmar los detalles sobre el color, el estilo y las medidas en un plazo de 24 horas tras finalizar su pedido.",
  retRefundCont29:"Si hay un problema de calidad, tome fotografías del envío original y comuníquese con nuestro servicio de atención al cliente para solicitar una devolución. Le notificaremos una vez que se haya procesado su solicitud y se podrá procesar una devolución o un reembolso dentro de los siete días posteriores a la recepción e inspección de su pedido.",
  retRefundCont30:`Por favor, comprenda que todos nuestros vestidos se hacen a pedido, lo que significa que los vestidos que pide son especiales para usted. Ponemos nuestro corazón en cada vestido, los vestidos no se pueden reponer ni revender si se devuelven. Por este motivo, no podemos aceptar pedidos de "compre muchos y quédese con uno". Para reducir el desperdicio ambiental y de recursos, no sugerimos pedidos de "compre muchos y quédese con uno". Una vez que se confirme su pedido, consideraremos que necesita todos los artículos de su pedido y seguiremos estrictamente nuestra política de devolución y reembolso.`,
  retRefundCont31:`Si desea conservar su pedido y realizar los ajustes, podemos ofrecerle una compensacíon. Entendemos que a veces es necesario realizar pequeños ajustes en su pedido para conseguir el ajuste perfecto. Por eso, ofrecemos una compensación limitada por arreglos en todos los vestidos. Esto incluye tanto las tallas estándar como los vestidos a medida. Sólo tienes que enviarnos una copia del recibo del sastre y tu número de pedido. Estos son nuestros límites de reembolso para los vestidos a medida en libras esterlinas :`,
  retRefundCont32:`Para vestidos con un precio de €99 o menos: límite de reembolso de €35`,
  retRefundCont33:`vestidos con precios entre €100 y €199 - Límite de compensación €50`,
  retRefundCont34:`Para todos los vestidos de más de €200: límite de compensación  €75`,
  retRefundCont35:"Después de una venta final, no podemos procesar devoluciones, reembolsos o cambios para todas las muestras de fábrica, artículos personalizados, velos o accesorios.",
  retRefundCont36:"No reembolsaremos los gastos de envío de los pedidos urgentes a menos que el pedido sea defectuoso. Si recibe un , pedido defectuoso, puede ponerse en contacto con nosotros, y vamos a inspeccionar y procesar el gasto de envío urgente tan pronto como sea posible.",
  retRefundCont37:"No podremos procesar ninguna devolución o reembolso de artículos de venta final.",
  retRefundCont38:`Tiene derecho a recibir un reembolso completo si recibe artículos defectuosos, dañados o enviados erróneamente. Sin embargo, si cree que los artículos se dañaron durante el envío, debe proporcionar la documentación de "Prueba de daños" de la empresa de mensajería. Deberá incluirla junto con las imágenes o los artículos cuando envíe su solicitud de devolución.`,
  retRefundCont39:"Para permitir ligeras modificaciones, nuestros sastres reservan tela adicional en las costuras de todos los vestidos. Entendemos que a veces un vestido terminado puede variar aproximadamente una pulgada en cualquier dirección de las medidas especificadas. Esto le permitirá hacer alteraciones para que el vestido le quede perfecto. ",
  retRefundCont40:"Supongamos que la talla de tu vestido se desvía más de un pulgada de las especificaciones del pedido. En ese caso, puede buscar un sastre local para que le haga los ajustes necesarios. En este caso, le compensaremos hasta el 40% del precio de compra del producto (excluidos los gastos de envío). Si elige esta opción, recuerde solicitar un recibo a su sastre. Deberá presentar una copia del recibo para solicitar la compensación. ",
  retRefundCont41:"En cuanto a la falta de coincidencia de color, tenga en cuenta que puede haber una ligera desviación de color debido a la configuración de la pantalla de su ordenador o dispositivo móvil. No obstante, si está convencido de que el color no es el que pidió, póngase en contacto con el Servicio de Atención al Cliente para ver si es posible una devolución o un reembolso.",
  retRefundCont42:"La mayoría de nuestros vestidos están hechos a mano por nuestros sastres experimentados por lo que podría haber algunos problemas durante la personalización, no vamos a tratar como problemas de calidad y no hay devolución y cambio.",
  retRefundCont43:"Le ofrecemos algunos consejos para hacer frente a estos problemas.",
  retRefundCont44:"1. Hilos. Utilice tijeras para cortarlo, por favor tenga cuidado.",
  retRefundCont45:"2. Arrugas. Planche la ropa. Planche el lado opuesto (lado interior) de las prendas con perlas, bordados u otros adornos.",
  retRefundCont46:"3. Ligera marca. Esta marca la suelen dejar los sastres cuando usan un bolígrafo borrable con agua para hacer sus trabajos. Es fácil de limpiar frotando suavemente con agua.",
  retRefundCont47:"¿Desea cambiar su vestido por otra talla o artículo? Lamentablemente, por el momento no ofrecemos servicio de cambio de ningún producto.  Todos nuestros vestidos se hacen a pedido, por lo que no tendremos vestidos adicionales para cambiar.  Si desea un artículo diferente, devuelva el pedido e inicie un nuevo pedido desde cero.",
  retRefundCont48:"1. Cree un ticket en su lista de pedidos dentro de los 14 días siguientes a la recepción de su pedido. Por favor, incluya el motivo de la devolución y fotografías para su verificación. Por favor, siga la imagen :",
  retRefundCont49:"2. Una vez que le hayamos enviado el permiso para devolver la mercancía, también le facilitaremos una dirección de devolución a través de Tickets. Todo lo que tiene que hacer es imprimir la dirección y adjuntarla/ponerla dentro del paquete con la mercancía que desea devolver.",
  retRefundCont50:"3. Una vez que haya enviado el paquete con éxito, notifique a nuestro representante de atención al cliente que su envío está en camino. También debe proporcionarle el número de seguimiento del envío proporcionado por su oficina postal local.",
  retRefundCont51:"4. Cuando recibamos su pedido devuelto, se lo notificaremos por correo electrónico. Inspeccionaremos el artículo y procesaremos el reembolso/devolución en un plazo de 3 a 5 días laborables. Si su devolución no se ajusta a nuestra política de devoluciones y reembolsos, es posible que no podamos tramitar el reembolso. El artículo debe estar en condiciones satisfactorias. Una vez aceptado su reembolso, puede esperar su abono en un plazo de 7 a 15 días laborables a través del método de pago original. Tenga en cuenta que el plazo de tramitación depende de la entidad emisora de su tarjeta o de su banco.",
  retRefundCont52:"Lamentablemente, debido a la situación actual, el reembolso tardará entre 3 y 5 días adicionales en procesarse. Gracias por su paciencia y comprensión.",
  retRefundCont53:"Condiciones",
  retRefundCont54:"* El artículo debe estar sin usar, sin lavar, sin alterar, sin daños y con las etiquetas originales adheridas. No podemos procesar la devolución de ningún artículo defectuoso.",
  retRefundCont55:"* Nos reservamos el derecho a rechazar la devolución si se recibe en un estado inaceptable o sin nuestro conocimiento previo.",
  retRefundCont56:"* No seremos responsables de artículos devueltos que se pierdan o dañen, conserve el recibo con la información de seguimiento.",
  retRefundCont57:`Todos nuestros artículos se fabrican bajo pedido, incluidos los artículos de tamaño estándar. Si desea cancelar un pedido por cualquier motivo, consulte nuestra política de cancelación a continuación: <a class="bold" href="{hrefOrderCan}">Política de cancelación de pedidos</a>.`,
  //project tailor made
  tailor1:'<p>¿Cuántas veces has encontrado ese vestido perfecto en las redes sociales pero no sabías dónde comprarlo? O quizás </p><p>has encontrado el vestido de tus sueños y luego has descubierto que el vendedor no tiene tu talla ni hace envíos a tu país.</p>', 
  tailor2:'<label>Sabemos lo difícil que puede resultar </label><span>encontrar el outfit ideal para una ocasión especial.</span>',
  tailor3:'<p>Y, ya sea que esté comprando para un cumpleaños, baile  de graduación, graduación o cualquier otra ocasión </p><p>memorable, solo contarán los mejores vestidos. Por eso creamos nuestro innovador servicio de vestidos a </p><p>medida, para que puedas tener un vestido tan especial como la ocasión.</p>',
  tailor4:'¿Cómo funciona nuestro servicio de vestidos a medida?',
  tailor5:'<p>Con nuestro sistema inteligente, puedes subir una foto de cualquier </p><p>vestido que encuentres en línea. Así, la próxima vez que estés </p><p>navegando por Pinterest y encuentres un vestido hermoso, guarda </p><p>la imagen y cárgala mediante la función de carga de imágenes. </p><p>Podemos recrear cualquier vestido que veas en línea, incluso si </p><p>es del sitio de un competidor.</p>',   
  tailor6:'<label>Los beneficios de elegir un </label><span>vestido a medida</span>',
  tailor7:'¿Por qué elegir un vestido a medida en lugar de una prenda fabricada en serie?',
  tailor8:'<p>En primer lugar, muchos vestidos de calle no son favorecedores porque no están </p><p>diseñados para tu tipo de cuerpo o altura. Sin embargo, un vestido a medida </p><p>promete un ajuste perfecto, ya que la prenda está diseñada y confeccionada </p><p>según tus medidas exactas.</p>',  
  tailor9:'<p>En segundo lugar, no es ningún secreto que muchos vestidos fabricados en serie utilizan tejidos de baja calidad. </p><p>Estos materiales pueden picar e irritar y no duran mucho. Por lo tanto, lo más probablees que solo te pongas el </p><p>vestido una vez. Sin embargo, nosotros confeccionamos todos nuestros vestidos a medida con tejidos de la mejor calidad para garantizar la máxima durabilidad y comodidad.</p>', 
  tailor10:'Además de cargar la foto, deberá proporcionarnos algunos datos para recibir un presupuesto preciso para la creación del vestido.',
  tailor11:'La forma de tu cuerpo/figura. Puedes indicarnos tu tipo de cuerpo, por ejemplo, pera, manzana, reloj de arena, o tu figura corporal en general, como delgada, con curvas o atlética.',
  tailor12:'La ocasión en la que planeas usar el vestido.',
  tailor13:'Puede realizar cualquier solicitud adicional o deseo de personalización para que el vestido sea muy especial y único.',
  tailor14:'enviar un hecho a medida',
  writeTailor:'Escribe un  hecho a medida',
  dressPic:'Fotos de vestidos',
  content:'Contenido',
  contReq:'El contenido es requerido.',
  tailor15:'Una vez que cargue sus fotos y proporcione los detalles anteriores, nuestros representantes de <p>servicio evaluarán la información y se comunicarán con usted para ofrecerle una cotización.</p>',
  tailor16:'Máximo 5 imágenes, 5 MB cada una. Formato: jpeg/jpg/png/bmp ¡Se agradecen las fotos nítidas!',
  tips:'Consejos',
  pleaseLogin:'Por favor, Identificarse',
  success:'¡Éxito!',
  //size chart
  specOccasion: 'Ocasiones especiales',
  juniorBrid: 'Damita de honor',
  jackets: 'Chaquetas/Chales',
  sizeTxt1: 'A continuación se muestran las tablas de tallas de los diferentes productos. Encuentra la talla adecuada para ti',
  sizeTxt2: "Es importante que conozcas tu propia talla a la hora de elegir un vestido. Solo conociendo tus medidas personales podrás tener un vestido que te quede mejor. Esto se debe a que cada diseñador puede utilizar únicamente sus propias reglas para definir las tallas de los vestidos. Por ejemplo, lo que podría ser una talla 6 en tu prenda de uso diario, podría ser una talla 10 en la misma talla en nuestros vestidos o en los vestidos de otro diseñador.",
  sizeTxt3: 'Por favor, compruebe el busto, la cintura y las caderas al elegir la talla. Para conocer sus medidas, consulte Cómo medir',
  sizeTxt4: 'Tabla de tallas estándar de vestidos',
  sizeTxt5: 'Tabla de tallas estándar de busto, cintura y cadera',
  sizeTxt6: 'Tabla de longitud de vestidos (desde el hueco hasta el dobladillo)',
  sizeTxt7: 'Algunos estilos añaden comodidad.Si se encuentra entre dos tallas, le sugerimos que llame y hable con un estilista o encargue el vestido en talla personalizada.',
  sizeTxt8: 'La medida del hueco al dobladillo para vestidos de largo hasta el suelo de tamaño estándar incluye 2 pulgadas adicionales para adaptarse a tacones altos.',
  sizeTxt9: '¿Tiene problemas para elegir la talla o requisitos adicionales sobre la talla? <a href="{hrefContact}">Contáctenos.</a>',
  sizeTxt10: 'Tabla de tallas de vestidos',
  sizeTxt11: 'Tabla de tallas de vestidos de talla grande',
  miniShort: 'Mini<span>/Corto</span>',
  kneeLen: 'Hasta la rodilla',
  teaLen: 'Hasta la tibia',
  ankleLen: 'Hasta el tobillo',
  floorLen: 'Hasta el suelo',
  dressLen: 'Longitud del vestido',
  reference: 'Sólo para su referencia',
  eurSize:'Talla de Europa',
  usSize: 'US Talla',
  euSize: 'EU Talla',
  ukSize: 'UK Talla',
  auSize: 'AU Talla',
  cupSize: 'Tamaño de la copa',
  shoulder: 'Hombro',
  toBust: 'Hombro al busto',
  sizeTxt12: "Por favor, comprueba el busto, la cintura y las caderas al elegir la talla. Para saber las medidas de tu niña, consulta cómo medir.",
  sizeTxt13: "Vestidos de pajecita",
  sizeTxt14: "La talla depende de los artículos. Comprueba la talla exacta en la página de detalles del producto.",
  sizeTxt15: 'Tabla de tallas de vestidos de pajecita',
  sizeTxt16: 'Tabla de tallas de vestidos de damita de honor',
  sizeTxt17: 'Tabla de tallas de chaqueta y chal',
  sizeTxt18: 'La tabla de tallas es sólo una guía general. Las medidas específicas varían con cada estilo.',
  sizeTxt19: 'Tabla de tallas de zapatos para hombre y mujer',
  sizeTxt20: 'Tabla de tallas de zapatos de danza',
  sizeTxt21: 'El tamaño de los vestidos de damita de honor depende de los artículos.Por favor, consulte la página de detalles del producto para conocer el tamaño exacto.',
  bustText1: "Esta es una medida de la parte más ancha de su busto o circunferencia corporal en el busto.Es una medida corporal que mide la circunferencia del torso de una mujer a la altura de los senos.",
  bustText2: "Envuelva la cinta alrededor de la parte más ancha de su busto y céntrela en su espalda de manera que quede nivelada en todo su perímetro.",
  bustText3: "<strong>*Consejos:</strong><br/>¡No se refiere a la talla de sujetador!<br/>Tus brazos deben estar relajados y caídos a los lados.Usa el sostén que piensas usar con tu vestido cuando tomes esto.",
  waistText1: "Esta es una medida de su cintura natural, o la parte más pequeña de su cintura.",
  waistText2: "Envuelva la cinta métrica alrededor de su cintura natural, manteniéndola paralela al piso. Inclínese hacia un lado para encontrar la depresión natural en su torso. Este es el tamaño natural de tu cintura.",
  hipsText1: "Esta es la medida de la circunferencia de la parte más completa de los glúteos.",
  hipsText2: 'Envuelva la cinta métrica alrededor de la parte más amplia de sus nalgas, que generalmente está entre 7 y 9 pulgadas por debajo de su cintura natural. Asegúrate de que la cinta métrica esté siempre paralela al suelo.',
  hemText1: "Esta es la distancia desde tu hueco hasta el dobladillo del vestido.",
  hemText2: "Pídele a un amigo que coloque la cinta métrica en tu hueco y jálala hasta obtener el largo de falda que desees.",
  hemText3: "<strong>*Consejos:</strong><br/>Para tomar esta medida, póngase de pie con los pies juntos.<br/>Definimos la longitud de la rodilla como el área justo debajo de las rótulas.<br/>Medir demasiado alto puede resultar en un vestido demasiado corto.<br/>Si se trata de un vestido largo hasta el suelo, asegúrese de medir sin usar zapatos.", 
  shoulderText1: "Esta es la distancia desde el borde del hombro izquierdo hasta el hueso prominente del cuello ubicado en el centro de la parte posterior del cuello y continúa hasta el borde del hombro derecho.",
  shoulderText2: 'Coloque la cinta métrica en la "parte superior" de los hombros. Mida desde el borde del hombro izquierdo hasta el hueso prominente del cuello ubicado en el centro de la nuca y continúe hasta el borde del hombro derecho.',
  sleeveText1: "Esta es la medida desde la costura del hombro hasta donde quieres que termine la manga.",
  sleeveText2: "Mida desde la costura del hombro hasta el largo de manga deseado con el brazo relajado a su costado para obtener la mejor medida posible.",
  sleeveText3: "<strong>*Consejos:</strong><br/>Medir con el brazo ligeramente doblado.",
  armscyeText1: "Esta es la medida de tu sisa.",
  armscyeText2: "<strong>*Consejos:</strong><br/>Para medir el contorno del brazo, debe enrollarse alrededor de la parte superior del hombro y luego por debajo de la axila.",
  armText2: "<strong>*Consejos:</strong><br/>Medir con el músculo relajado.",
  sizeTxt22: 'Por favor siga cuidadosamente nuestro ',
  sizeTxt23: ' Guía para tomar medidas.',
  sizeTxt24: 'Tabla de tallas de boleros',
  sizeTxt25: 'Tabla de tallas de boleros de talla grande',
  sizeTxt26: 'Tabla de tallas de sujetadores',
  sizeTxt27:'La medida de hueco a suelo de nuestros vestidos estándar ya incluye 2 pulgadas (aprox.5 cm) adicionales para adaptarse a tacones altos.',
  measureSize:'Medidas Tamaño',
  underBust: 'Bajo el busto',
  bustGap: 'Brecha de busto fluctuante',
  oneSize:'Talla única',
  //color chart
  "Dark Navy": "Azul marino oscuro",
  "Navy Blue": "Azul Marino",
  "Dusty Blue": "Azul polvoriento",
  "Ink Blue": "Azul tinta",
  "Stormy": "Tormenta",
  "Slate Blue": "Azul Pizarra",
  "Royal Blue": "Azul real",
  "Ocean Blue": "Azul océano",
  "Ice Blue": "Azul hielo",
  "Blue": "Azul",
  "Light Blue": "Azul Claro",
  "Sky Blue": "Celeste",
  "Mist": "Niebla",
  "Cabernet": "Cabernet",
  "Burgundy": "Borgoña",
  "Red": "Rojo",
  "Mulberry": "Mora",
  "Rust": "Bermellón",
  "Watermelon": "Sandía",
  "Papaya": "Papaya",
  "Sunset": "Puesta de sol",
  "Peach": "Melocotón",
  "Light Pink": "Rosa claro",
  "Pearl Pink": "Rosa perla",
  "Petal": "Rosa Palo",
  "Blushing Pink": "Rubor Rosa",
  "Candy Pink": "Rosa caramelo",
  "Coral": "Coral",
  "Dusty Pink": "Rosa Polvoriento ",
  "Dusty Rose": "Rosa polvorienta ",
  "Peacock": "Verde pavo real",
  "Blush": "Sonrojo",
  "Tahiti": "Tahití",
  "Lilac": "Lila",
  "Lavender": "Lavanda",
  "Dusk": "Dusk",
  "Wisteria": "Glicina",
  "Mauve": "Mauve",
  "Vintage-Mauve": "Malva Clásico",
  "Vintage Mauve": "Malva Clásico",
  "Orchid": "Orquídea",
  "Grape": "Uva",
  "Fuchsia": "Fucsia",
  "Regency": "Violeta",
  "Plum": "Ciruela",
  "Chocolate": "Chocolate",
  "Dark Green": "Verde oscuro",
  "Teal": "Cerceta",
  "Emerald": "Esmeralda",
  "Hunter Green": "Verde Militar",
  "Olive": "Verde oliva",
  "Celadon": "Celadón",
  "Lime Green": "Verde lima",
  "Dusty Sage": "Salvia Polvoriento ",
  "Sage": "Salvia",
  "Mint Green": "Verde menta",
  "Turquoise": "Turquesa",
  "Spa": "Balneario",
  "Jade": "Jade",
  "Beige": "Beis",
  "Yellow": "Amarillo",
  "Daffodil": "Narciso",
  "Champagne": "Champán",
  "Gold": "Oro",
  "Orange": "Naranja",
  "Marigold": "Caléndula",
  "Tangerine": "Mandarina",
  "Taupe": "Taupe",
  "Mushroom": "Seta",
  "Silver": "Plata",
  "White": "Blanco",
  "Ivory": "Marfil",
  "Potrobello": "Color tabaco",
  "Steel Grey": "Gris Acero",
  "Black": "Negro",
  'colorNote': `Tenga en cuenta que las fotografías pueden ser ligeramente diferentes del artículo real en términos de color, debido a la iluminación, las sombras y las mejoras de la fotografía durante la sesión fotográfica. Los ajustes de gama de colores, brillo y contraste de la pantalla de la computadora a veces también generan confusión.`,
  "Chiffon": "Gasa",
  "Organza": "Organdí de seda",
  "Satin": "Satén",
  "Taffeta": "Tafetán",
  "Elastic Satin": "Satén elástico",
  "Tulle": "Tul",
  "Lace": "Encaje",
  "Jersey": "Jersey",
  "Charmeuse": "Tela Charmeuse",
  "Moss": "Musgo",
  //contact us
  contactUs1:"¿NECESITAS AYUDA?",
  contactUs2:"Agradecemos todas las preguntas sobre compras, pedidos y servicio posventa. Tenemos representantes amables listos para ayudarlo.",
  contactUs3:`Si tiene alguna pregunta, visite primero nuestras <a href="{hrefFaq}">preguntas frecuentes</a>. El centro de ayuda ofrece respuestas a las preguntas más comunes.`,
  contactUs4:"Si estás considerando una compra",
  contactUs5:"En caso de tener alguna pregunta o necesitar más información antes de realizar un pedido, puede contactarnos en cualquier momento a través del Livechat en la parte inferior derecha de la página.Si nuestros representantes de Livesupport no están disponibles, puede enviarnos un correo electrónico las 24 horas del día, los 7 días de la semana y nos comunicaremos con usted lo antes posible.",
  contactUs6:"Si ya has realizado una compra",
  contactUs7:"El departamento de servicio está aquí para ayudarlo con consultas sobre sus pedidos en procesamiento actual y los artículos que ya recibió. Tenga en cuenta que todas las consultas se manejan minuciosamente, ninguna solicitud queda sin respuesta, tratamos de responder cada solicitud dentro de un día hábil.",
  contactUs8:"Crear un ticket",
  contactUs9:"Hora de trabajo: UTC 1 a.m. to 11 a.m.",
  contactUs18:"Por favor, no envíe ningún artículo de devolución a la dirección anterior, las devoluciones enviadas a esta dirección no pueden ser procesadas.",
  contactUs19:'Si necesita devolver un producto, póngase en contacto con el servicio de atención al cliente <a href="{hrefTicket}">enviando una Consultas Postventa</a>.',
  preSale: 'PREVENTA',
  afterSale: 'POSTVENTA',
  whatsapp: 'Whatsapp',
  addressList:  [
    'Información sobre la oficina de Hong Kong',
    'Empresa : DIY FASHION LIMITED',
    'Número de registro : 1994949',
    'Dirección :Unidad D, 16/F, One Capital Place, 18 Luard Road, Wan Chai Hong Kong. (No es la dirección del devoluciones)',
  ],
  //payment methods
  paymentInfo1: '¡Disfrute de una experiencia de compra segura y fácil en stacees.com!',
  paymentInfo2: 'Aceptamos los siguientes métodos de pago. Puedes elegir el que más te guste :',
  creditDebit: 'Tarjeta de Crédito o Débito :',
  //terms conditions
  overview:'Resumen',
  termCont1:`Este sitio web está gestionado por DIY FASHION LIMITED y/o sus entidades afiliadas FEELTIMES LIMITED, filial en el Reino Unido (denominadas colectivamente "DIY FASHION LIMITED", "nosotros", "nos" o "nuestro", también conocido como stacees.com. En todo el sitio, los términos "nosotros", "nos" y "nuestro" se refieren a DIY FASHION LIMITED. DIY FASHION LIMITED  ofrece este sitio web, incluyendo toda la información, herramientas y servicios disponibles en este sitio para usted, el usuario, condicionado a la aceptación de todos los términos, condiciones, políticas y avisos aquí establecidos. En todo el sitio, el término “STACEES” se refiere a DIY FASHION LIMITED.`,
  termCont2:"Al visitar nuestro sitio web y/o comprarnos, usted está participando en nuestro servicio y acepta quedar vinculado por los siguientes términos y condiciones ('Términos del Servicio', 'Términos') incluidos estos términos y condiciones adicionales y las políticas a las que se hace referencia en el presente documento y/o disponibles mediante hipervínculo. Estas Condiciones del servicio se aplican a todos los usuarios del sitio, incluidos, entre otros, los usuarios que son navegadores, proveedores, clientes, comerciantes y/o colaboradores de contenidos.Lea atentamente estas condiciones de uso antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio web, acepta quedar vinculado por estas condiciones de servicio. Si no acepta todos los términos y condiciones de este acuerdo, no podrá acceder al sitio web ni utilizar ninguno de los servicios. Si estas condiciones del servicio se consideran una oferta, su aceptación se limita expresamente a estas condiciones del servicio.Cualquier nueva función o herramienta añadida a la tienda actual también estará sujeta a las Condiciones del servicio. Puede consultar la versión más actualizada de las Condiciones del servicio en cualquier momento en esta página. Nos reservamos el derecho a actualizar, cambiar o sustituir cualquier parte de estas Condiciones del servicio mediante la publicación de actualizaciones y/o cambios en nuestra página web. Es su responsabilidad revisar esta página periódicamente para comprobar si se han producido cambios. El uso continuado o el acceso al sitio web después de la publicación de cualquier cambio constituye su aceptación de dichos cambios.",
  termCont3:"LEY APLICABLE: Estos términos de servicio y cualquier acuerdo por separado mediante el cual le proporcionemos se regirán e interpretarán de conformidad con las leyes de Hong Kong.",
  termCont4:"Condiciones de la tienda en línea",
  termCont5:"Al aceptar estas términos de servicio, usted declara que es mayoría de edad en su estado o provincia de residencia, o que ha alcanzado la mayoría de edad en su estado o provincia de residencia y que nos ha dado permiso para permitir que cualquiera de los miembros menores de su familia utilice el sitio.",
  termCont6:"No puede utilizar nuestros productos para ningún propósito ilegal o no autorizado ni puede, en el uso del Servicio, violar ninguna ley en su jurisdicción (incluidas, entre otras, las leyes de derechos de autor).",
  termCont7:"No debe transmitir ningún malware, virus o código de naturaleza destructiva.",
  termCont8:"La violación o incumplimiento de cualquiera de las términos dará lugar a la rescisión inmediata de sus servicios.",
  termCont9:"Condiciones generales",
  termCont10:"Nos reservamos el derecho a negar el servicio a cualquier persona en cualquier momento y por cualquier motivo.",
  termCont11:"Usted entiende que su contenido (excluida la información de la tarjeta de crédito) puede transmitirse sin cifrar e implica lo siguiente: (a) transmisiones a través de varias redes, (b) cambios para ajustarse y adaptarse a los requisitos técnicos de las redes o dispositivos de conexión. La información de las tarjetas de crédito siempre se encripta durante su transmisión a través de una red.",
  termCont12:"Usted acepta no reproducir, duplicar, copiar, vender, revender o explotar ninguna parte del Servicio, uso del Servicio o acceso al Servicio o cualquier contacto en el sitio web a través del cual se brinda el servicio, sin nuestro expreso permiso por escrito.",
  termCont13:"Los títulos utilizados en este acuerdo se incluyen únicamente para conveniencia y no limitarán ni afectarán de otro modo estos términos.",
  termCont14:"Exactitud, integridad y puntualidad de la información",
  termCont15:"No nos hacemos responsables si la información disponible en este sitio no es precisa, completa o actual. El material de este sitio se proporciona únicamente con fines de información general y no debe confiarse en él ni utilizarse como única base para tomar decisiones sin consultar fuentes de información primarias, más precisas, más completas o más actuales. Cualquier confianza depositada en el material de este sitio corre por su cuenta y riesgo.",
  termCont16:"Este sitio web puede contener cierta información histórica. Dicha información no es actual y se proporciona únicamente como referencia. Nos reservamos el derecho a modificar el contenido de este sitio en cualquier momento, pero no tenemos la obligación de actualizar ninguna información de nuestro sitio. Usted acepta que es su responsabilidad supervisar los cambios en nuestro sitio.",
  termCont17:"Modificaciones al servicio y precios",
  termCont18:"Los precios de nuestros productos están sujetos a cambios sin previo aviso. Nos reservamos el derecho a modificar o interrumpir el servicio (o cualquier parte del mismo) en cualquier momento y sin previo aviso. No seremos responsables ante usted ni ante terceros por ninguna modificación, cambio de precio, suspensión o interrupción del servicio.",
  termCont19:"Productos o servicios",
  termCont20:"Algunos productos o servicios pueden estar disponibles exclusivamente en línea a través de nuestro sitio web. Estos productos o servicios pueden tener cantidades limitadas y están sujetos a devolución o cambio únicamente de acuerdo con nuestra Política de devoluciones.",
  termCont22:`Hemos hecho todo lo posible para mostrar los colores y las imágenes de nuestros productos con la mayor exactitud posible. No podemos garantizar que la visualización de cualquier color en el monitor de su ordenador sea exacta.`,
  termCont23:"Nos reservamos el derecho, pero no estamos obligados, a limitar las ventas de nuestros productos o Servicios a cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho caso por caso. Nos reservamos el derecho a limitar las cantidades de cualquier producto o servicio que ofrezcamos. Todas las descripciones de productos o precios de productos están sujetos a cambios en cualquier momento sin previo aviso, a nuestra entera discreción,a nuestra entera discreción.Nos reservamos el derecho a interrumpir cualquier producto en cualquier momento. Cualquier oferta de cualquier producto o servicio proporcionado en el Sitio es nula donde esté prohibida.",
  termCont24:"No garantizamos que la calidad de los productos, servicios, información u otro material comprado u obtenido por usted cumplirá con sus expectativas, o que cualquier error en el Servicio será corregido.",
  termCont25:"Exactitud de la información sobre facturación y cuentas",
  termCont26:"Nos reservamos el derecho a rechazar cualquier pedido. Podemos, a nuestra entera discreción, limitar o cancelar el número de compras por persona, por hogar o por pedido.Estas restricciones pueden incluir pedidos realizados por o bajo la misma cuenta de cliente, con la misma tarjeta de crédito y/o utilizando la misma dirección de facturación y/o envío. Si modificamos o cancelamos un pedido, intentaremos notificárselo poniéndonos en contacto con la dirección de correo electrónico y/o la dirección de facturación o el número de teléfono que nos haya facilitado al realizar el pedido. Nos reservamos el derecho a limitar o prohibir los pedidos que, a nuestro juicio, parezcan haber sido realizados por un comerciante, revendedor o distribuidor.",
  termCont27:"Usted se compromete a proporcionar información actual, completa y precisa sobre la compra y la cuenta para todas las compras realizadas en nuestra tienda. También se compromete a actualizar puntualmente su cuenta y otra información, como su dirección de correo electrónico, su número de tarjeta de crédito y la fecha de caducidad, para que podamos completar su transacción y contactar con usted cuando sea necesario.",
  termCont28:'Para obtener más detalles, consulte nuestra <a class="cr-bd" href="{hrefReturn}">política de devoluciones.</a>',
  termCont29:"Enlaces de terceros",
  termCont30:"Determinados contenidos, productos y servicios disponibles a través de nuestros Servicios pueden incluir material de terceros.",
  termCont31:"Los enlaces de terceros en el sitio web pueden dirigirle a sitios web de terceros que no están afiliados a nosotros. No somos responsables de comprobar o evaluar su contenido o exactitud, y no garantizamos ni aceptaremos responsabilidad alguna por materiales o sitios web de terceros, ni por otros materiales, productos o servicios de terceros.",
  termCont32:"No nos hacemos responsables de ningún daño o perjuicio relacionado con la compra o el uso de bienes, servicios, recursos, contenidos o cualquier otra transacción realizada en relación con sitios web de terceros. Revise detenidamente las políticas y prácticas de terceros y asegúrese de comprenderlas antes de realizar cualquier transacción. Las quejas, reclamaciones, inquietudes o preguntas relacionadas con productos de terceros deben dirigirse a dichos terceros.",
  termCont33:"Comentarios de los usuarios,Retroalimentación y otros opiniones",
  termCont34:"Si, a petición nuestra, envía ciertos comentarios específicos (por ejemplo, entradas) o, si no lo solicitamos, envía ideas, sugerencias, recomendaciones, propuestas, planes u otros materiales, ya sea en línea, por correo electrónico, por correo postal o de otro modo (colectivamente, ‘comentarios’), usted acepta que podamos editar, reproducir, distribuir, traducir y utilizar de otro modo cualquier comentario que nos envíe en cualquier medio y en cualquier momento sin restricciones. No estamos obligados a (1) mantener la confidencialidad de ningún comentario; (2) compensarle por ningún comentario; o (3) responder a ningún comentario.",
  termCont35:"Podemos, pero no estamos obligados a, supervisar, editar o eliminar el contenido que determinemos, a nuestra entera discreción, que es ilegal, ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno o de otra manera objetable o que viole los derechos de propiedad intelectual de cualquier parte o estas términos de servicio.",
  termCont36:"Usted acepta que sus comentarios no violarán ningún derecho de terceros, incluidos los derechos de autor, marca registrada, privacidad, personalidad u otros derechos personales o de propiedad. Asimismo, acepta que sus Comentarios no contendrán material difamatorio o de otro modo ilegal, abusivo u obsceno, ni contendrán virus informáticos u otro software malicioso que pueda afectar de algún modo al funcionamiento de los Servicios o de cualquier sitio web relacionado. No utilizará una dirección de correo electrónico falsa, ni se hará pasar por otra persona que no sea usted, ni nos engañará a nosotros o a terceros en cuanto al origen de los Comentarios. Usted acepta plena responsabilidad por cualquier comentario que publique y por su exactitud. No nos hacemos responsables de los comentarios publicados por usted o por terceros.",
  termCont37:"Información personal",
  termCont38:"La información personal que envíe a través de la Tienda está sujeta a nuestra Política de Privacidad.",
  termCont39:"Errores, inexactitudes y omisiones",
  termCont40:"Ocasionalmente puede haber información en nuestro sitio o en el Servicio que contenga errores tipográficos, inexactitudes u omisiones que puedan estar relacionadas con descripciones de productos, precios, promociones, ofertas, gastos de envío de productos, tiempos de tránsito y disponibilidad. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión, y de cambiar o actualizar la información o cancelar pedidos si cualquier información en el Servicio o en cualquier sitio web relacionado es inexacta en cualquier momento sin previo aviso (incluso después de que usted haya enviado su pedido).",
  termCont41:"No tenemos obligación alguna de actualizar, modificar o aclarar la información de los Servicios o de cualquier sitio web relacionado, incluida, a título meramente enunciativo y no limitativo, la información sobre precios, salvo que así lo exija la ley. Si no se aplica una fecha de actualización o actualización especificada en los Servicios o en cualquier sitio web relacionado, se considerará que toda la información de los Servicios o de cualquier sitio web relacionado ha sido modificada o actualizada.",
  termCont42:"Usos prohibidos",
  termCont43:"Además de otras prohibiciones establecidas en las términos de servicio, no podrá utilizar el Sitio ni su Contenido: (a) para ningún fin ilícito; (b) para solicitar a otros que realicen o participen en ningún acto ilícito; (c) para infringir cualquier estatuto, norma, ley u ordenanza local internacional, federal, provincial o estatal; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de otros; (e) para discriminar por motivos de género, orientación sexual, religión, raza, etnia, raza, edad, nacionalidad o discapacidad, acosar, abusar, insultar, herir, difamar, calumniar, menospreciar, intimidar o discriminar; (f) enviar información falsa o engañosa; (g) cargar o transmitir virus o cualquier otro tipo de código malicioso que afecte o pueda afectar de algún modo a la funcionalidad o al funcionamiento de los Servicios o de cualquier sitio web relacionado, otro sitio web o Internet; (h) recopilar o rastreo de información personal sobre otros; (i) spamming, phishing, pharming, pretexting, spidering, web-crawling o crawling; (j) para cualquier fin obsceno o inmoral; (k) para interferir o eludir las funciones de seguridad de los Servicios o de cualquier sitio web relacionado, otro sitio web o Internet. Nos reservamos el derecho a dar de baja al usuario por infringir cualquier uso prohibido de los Servicios o de cualquier sitio web relacionado.",
  termCont44:"Descargo de reponsabilidad; Limitación de responsabilidad",
  termCont45:"No garantizamos que el uso de nuestro servicio sea ininterrumpido, puntual, seguro o libre de errores.",
  termCont46:"No garantizamos que los resultados que puedan obtenerse del uso de los Servicios sean precisos o fiables.",
  termCont47:"Usted acepta que podemos eliminar los Servicios de vez en cuando indefinidamente o cancelar los Servicios en cualquier momento sin previo aviso.",
  termCont48:"Usted acepta expresamente que el uso o la imposibilidad de usar el servicio es por su cuenta y riesgo. el servicio y todos los productos y servicios que se le entreguen a través del servicio (a menos que indiquemos expresamente lo contrario) se proporcionan «tal cual» y «según disponibilidad» para su uso, sin declaraciones, garantías o condiciones expresas o implícitas de ningún tipo, incluidas todas las garantías o condiciones implícitas de comerciabilidad, calidad comercial, idoneidad para un fin determinado, durabilidad, titularidad y no infracción. ",
  termCont49:"En ningún caso DIY FASHION LIMITED, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier lesión, pérdida, reclamo, o cualquier daño directo, indirecto, incidental, punitivo, especial o consecuente de cualquier tipo, incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo, o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), responsabilidad objetiva o de otro tipo, derivada de su uso de cualquiera de los servicios o cualquier producto obtenido mediante el servicio, o por cualquier otra reclamación relacionada de alguna manera con su uso del servicio o cualquier producto, incluyendo, pero no limitado a, cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurridos como resultado del uso del servicio o cualquier contenido (o producto) publicado, transmitido o puesto a disposición a través del servicio, incluso si se advierte de su posibilidad. Dado que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuenciales o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitará al máximo permitido por la ley.",
  termCont50:"indemnizar",
  termCont51:"Usted acepta indemnizar, defender y eximir de responsabilidad a DIY FASHION LIMITED y a nuestras matrices, subsidiarias, afiliados, socios, funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, indemnes de cualquier reclamo o demanda. incluyendo honorarios razonables de abogados, realizados por cualquier tercero debido a o que surjan de su incumplimiento de estos términos de servicio o de los documentos que incorporan por referencia, o su violación de cualquier ley o los derechos de un tercero.",
  termCont52:"Divisibilidad",
  termCont53:"Si se determina que alguna disposición de estas condiciones del servicio es ilegal, nula o inaplicable, dicha disposición seguirá siendo aplicable en la máxima medida permitido por la legislación aplicable, y la parte inaplicable se considerará separada de estas condiciones del servicio.  Dicha determinación no afectará a la validez y aplicabilidad de las demás disposiciones restantes.",
  termCont54:"Terminación",
  termCont55:"Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de terminación sobrevivirán a la terminación de este acuerdo para todos los efectos.",
  termCont56:"Estos términos de servicio son efectivas a menos y hasta que usted o nosotros las rescindamos. Puede rescindir estas Condiciones del servicio en cualquier momento notificándonos que ya no desea utilizar nuestros Servicios, o cuando deje de utilizar nuestro sitio.",
  termCont57:"Si a nuestro juicio usted incumple, o sospechamos que ha incumplido, cualquier término o disposición de estas Condiciones de servicio, también podremos rescindir este acuerdo en cualquier momento sin previo aviso y usted seguirá siendo responsable de todos los importes adeudados hasta la fecha de rescisión inclusive; y/o en consecuencia podremos denegarle el acceso a nuestros Servicios (o a cualquier parte de los mismos).",
  termCont58:"No desprestigio. Durante el Plazo y después de él, usted acepta no realizar ninguna acción que tenga como objetivo, o que se pueda esperar razonablemente que dañe, a la Compañía o su reputación o que se pueda esperar razonablemente que genere publicidad no deseada o desfavorable para la Compañía en caso de que no haya recibido respuesta de nosotros durante al menos 4 días hábiles. Si viola este término, nos reservamos el derecho de rescindir el contrato sin compensación alguna.",
  termCont59:"Acuerdo completo",
  termCont60:"El hecho de que no ejerzamos o hagamos valer cualquier derecho o disposición de estas Condiciones del servicio no constituirá una renuncia a dicho derecho o disposición.",
  termCont61:"Estos términos de servicio y cualquier política o regla operativa publicada por nosotros en este sitio o con respecto al servicio constituyen el acuerdo y entendimiento completo entre usted y nosotros y rigen su uso del Servicio, reemplazando cualquier acuerdo, comunicación y propuesta anterior o contemporánea, ya sea oral o escrita, entre usted y nosotros (incluidas, entre otras, cualquier versión anterior de los Términos de Servicio).",
  termCont62:"Cualquier ambigüedad en la interpretación de estas condiciones del servicio no se interpretará en detrimento de la parte redactora.",
  termCont63:"Cambios en las Términos de servicio",
  termCont64:"Puede consultar la versión más actualizada de las Condiciones del servicio en cualquier momento en esta página. ",
  termCont65:"Nos reservamos el derecho, a nuestra entera discreción, de actualizar, cambiar o reemplazar cualquier parte de estos términos de servicio mediante la publicación de actualizaciones y cambios en nuestro sitio web.",
  termCont66:"Es su responsabilidad revisar esta página periódicamente para comprobar si se han producido cambios. El uso continuado o el acceso al sitio web después de la publicación de cualquier cambio constituye su aceptación de dichos cambios.",
  termCont67:"¿Preguntas sobre los términos?",
  //privacyPolicy
  privacyCont1:`Este sitio web está gestionado por DIY FASHION LIMITED y/o sus entidades afiliadas FEELTIMES LIMITED, filial en el Reino Unido (denominadas colectivamente "DIY FASHION LIMITED", "nosotros", "nos" o "nuestro", también conocido como stacees.com.Nos comprometemos a proteger y respetar la privacidad de sus datos personales. Esta declaración de privacidad explica cómo STACEES recopila, utiliza, transfiere y divulga sus datos. Se aplica a los datos recogidos cuando usted utiliza nuestros sitios web, cuando interactúa con nosotros a través de las redes sociales, el correo electrónico o el teléfono, o cuando participa en nuestros concursos o eventos. Incluye :`,
  privacyCont2:'Los datos personales que recogemos',
  privacyCont3:'Cómo recogemos sus datos',
  privacyCont4:'Cómo utilizamos sus datos',
  privacyCont5:'Preferencias de marketing, publicidad y cookies',
  privacyCont6:'Enlaces a otros sitios web y terceros',
  privacyCont7:'Cómo compartimos sus datos',
  privacyCont8:'Sus derechos',
  privacyCont9:'Cambios a esta política de privacidad',
  privacyCont10:'Cómo contactarnos',
  privacyCont11:'Quién es STACEES',
  privacyCont12:'STACEES es uno de los principales minoristas de moda en línea. Diseñamos, producimos, comercializamos y vendemos ropa a mujeres  consumidoras de prácticamente todos los países del mundo.',
  privacyCont13:`STACEES (denominado colectivamente "STACEES", "nosotros", "nos"y "nuestro" en este aviso de privacidad) es el controlador y responsable de sus datos personales recogidos a través del sitio web www.stacees.com (el "sitio web").`,
  privacyCont14:'Somos responsables de supervisar los detalles de la protección de datos en relación con las cuestiones cubiertas por esta declaración de privacidad,nuestros Información detallada en la sección ‘Cómo contactarnos’ al final de este aviso.',
  privacyCont15:'Nuestro compromiso contigo',
  privacyCont16:'Nos tomamos muy en serio la protección de sus datos personales y los trataremos de forma justa, lícita y transparente. Esta declaración de privacidad describe los datos personales que recopilamos sobre usted y la forma en que los utilizamos.',
  privacyCont17:'Solo recogeremos y utilizaremos sus datos personales para los siguientes fines :',
  privacyCont18:'Cumplir con su(s) pedido(s)',
  privacyCont19:'mantenerle al día de las últimas ofertas y tendencias',
  privacyCont20:'ofrecerle una mejor experiencia de compra',
  privacyCont21:'ayúdanos a hacer que nuestro marketing sea más relevante para ti y sus intereses.',
  privacyCont22:'mejorar nuestros servicios',
  privacyCont23:'cumplir nuestras responsabilidades legales',
  privacyCont24:'Cómo mantenemos sus datos seguros',
  privacyCont25:'Contamos con las salvaguardas organizativas y las medidas de seguridad adecuadas para proteger sus datos de pérdidas accidentales, del uso o acceso no autorizado, de su alteración o divulgación.',
  privacyCont26:'La comunicación entre su navegador y nuestro sitio web utiliza una conexión cifrada segura cuando se trata de sus datos personales. Ciframos sus datos y los almacenamos en servidores seguros ocultos tras un cortafuegos.',
  privacyCont27:'Requerimos que cualquier tercero contratado para procesar sus datos personales en nuestro nombre disponga de medidas de seguridad para proteger sus datos y tratarlos de conformidad con la ley.',
  privacyCont28:'En el desafortunado caso de que se produzca una violación de los datos personales, se lo notificaremos a usted y a cualquier autoridad supervisora aplicable cuando así lo requiera la ley.',
  privacyCont30:'Por datos personales se entiende cualquier información sobre una persona a partir de la cual esta pueda ser identificada. No incluye los datos anonimizados, en los que se ha suprimido la identidad y la información identificativa.',
  privacyCont31:'Aunque nuestro sitio web está diseñado para un público general, no recopilaremos conscientemente datos de niños menores de 13 años ni les venderemos productos. Si usted es menor de 13 años, no está autorizado a utilizar o enviar sus datos al sitio web.',
  privacyCont32:'Se recogen los siguientes grupos de datos personales:',
  privacyCont33:'<strong>Los datos de Identidad</strong> incluyen información como: nombre, apellido, cargo, fecha de nacimiento (opcional), descripción personal (opcional), foto (opcional) y sexo (opcional).',
  privacyCont34:'<strong>Los datos de contacto</strong> incluyen la siguiente información:dirección de correo electrónico, dirección de facturación, dirección de envío, ubicación, país, número de teléfono, número de socio del programa de fidelización, ID de Amazon (si inicia sesión con Amazon) e ID de redes sociales (si inicia sesión mediante redes sociales).',
  privacyCont35:'<strong>Los datos financieros</strong> incluyen información como: detalles de la tarjeta de pago o información de la cuenta de PayPal.',
  privacyCont36:'<strong>Los datos de transacción</strong> incluyen información como los detalles de sus compras y el cumplimiento de sus pedidos (como número de cesta, número de pedido, subtotal, título, divisa, descuentos, envío, número de artículos, número de producto, precio de artículo único, categoría, impuestos, etc.); pagos realizados entre usted y nosotros y detalles de otros productos y servicios que haya obtenido de nosotros; correspondencia o comunicaciones con usted respecto a sus pedidos y detalles de cualquier recompensa y bonificación concedida.',
  privacyCont37:'<strong>Los datos técnicos</strong> incluyen información como: detalles de los dispositivos que utiliza para acceder a nuestros servicios, su dirección de protocolo de Internet (IP), datos de inicio de sesión, su nombre de usuario y contraseña, tipo y versión de navegador, configuración de zona horaria y ubicación, tipos y versiones de complementos del navegador, sistema operativo y plataforma.',
  privacyCont38:'<strong>Los datos de perfil</strong> incluyen la siguiente información: su compra o pedido, intereses en productos y estilos, preferencias, comentarios y respuestas a encuestas.',
  privacyCont39:'<strong>Los datos de uso</strong> incluyen la siguiente información: cómo y cuándo utiliza nuestro sitio web/aplicación, cómo navega por él, qué busca; estadísticas de rendimiento del sitio web/aplicación, tráfico, ubicación, registros web y otros datos de comunicaciones; actividades del programa de fidelización; detalles de cualquier otro producto y servicio de STACEES que utilice.',
  privacyCont40:'<strong>Los datos de marketing y comunicaciones</strong> incluyen información como: sus preferencias para recibir marketing nuestro y de terceros y sus preferencias de comunicación.',
  privacyCont41:'También recopilamos, utilizamos y compartimos <strong>Datos Agregados</strong>, como estadísticas o datos demográficos, para cualquier fin. Los datos agregados pueden derivarse de los datos personales, pero no se consideran datos personales,ya que <strong>no</strong> revelan ni directa ni indirectamente la identidad de las personas. Por ejemplo, podemos agregar sus datos de uso para calcular el porcentaje de usuarios que acceden a una función específica del sitio web. Sin embargo, si combinamos o conectamos los datos agregados con sus datos personales de modo que puedan identificarle directa o indirectamente, trataremos los datos combinados como datos personales y los utilizaremos de conformidad con el presente aviso de privacidad.',   
  privacyCont43:'Podemos recopilar datos personales sobre usted de las siguientes maneras :',
  privacyCont44:'<strong>Interacciones directas</strong> - puede facilitarnos sus datos de identidad, de contacto, financieros, de transacciones, de perfil y de marketing y comunicaciones (tal y como se han descrito anteriormente) rellenando formularios, introduciendo información en línea o manteniendo correspondencia con nosotros por correo postal, teléfono, correo electrónico, teléfono o de cualquier otra forma.Esto incluye los datos personales que nos facilite, por ejemplo, cuando:',
  privacyCont45:'Crear una cuenta o comprar productos en nuestro sitio web;',
  privacyCont46:'Suscríbete a nuestro boletín, foros de discusión, sitios de redes sociales o crea listas de deseos ;',
  privacyCont47:'Participar en un concurso ;',
  privacyCont48:'Ingresar en un programa de fidelización de STACEES;',
  privacyCont49:'Completar una encuesta voluntaria de investigación de mercado ;',
  privacyCont50:'Póngase en Contáctenos para realizar una consulta o notificar un problema (por teléfono, correo electrónico, redes sociales o servicio de mensajería) ;',
  privacyCont51:'Utilizar la función "recomendar a un amigo" de nuestro sitio web; o',
  privacyCont52:'Cuando inicia sesión en nuestro sitio web a través de las redes sociales.',
  privacyCont53:'<strong>Tecnologías o interacciones automatizadas</strong> – al interactuar con nuestro sitio web, podemos recopilar automáticamente los siguientes tipos de datos (todos ellos descritos anteriormente):Datos técnicos sobre su dispositivo, datos de uso sobre su comportamiento y patrones de navegación, así como datos de contacto al realizar tareas.Los pedidos realizados a través de nuestro sitio web quedan sin cumplimentar, por ejemplo, si el pedido está incompleto o se abandona la cesta de la compra. Recopilamos estos datos utilizando cookies, registros de servidor y otras tecnologías similares.',
  privacyCont54:'<strong>Terceros</strong> – podemos datos personales sobre usted de varios terceros,incluidos:',
  privacyCont55:'Datos técnicos de terceros, incluidos proveedores de análisis como Google. Consulte más información en la sección "Preferencias de marketing, anuncios y cookies".',
  privacyCont56:'Datos técnicos de las redes de afiliados a través de las cuales ha accedido a nuestro sitio web ;',
  privacyCont57:'Datos de identidad y contacto de plataformas de redes sociales cuando inicia sesión en nuestro sitio web utilizando dichas plataformas de redes sociales;',
  privacyCont58:'Datos de contacto, financieros y de transacciones de proveedores de servicios técnicos, de pago y de entrega.',
  privacyCont60:'Base jurídica del tratamiento de sus datos personales',
  privacyCont61:'Solo recogeremos y trataremos sus datos personales cuando tengamos una base jurídica para hacerlo. Como responsables del tratamiento de datos, la base jurídica de nuestra recogida y uso de sus datos personales varía en función de la forma y la finalidad para la que los hayamos recogido.',
  privacyCont62:'Solo recopilaremos sus datos personales cuando :',
  privacyCont63:'tenemos su consentimiento para hacerlo, o',
  privacyCont64:'necesitamos sus datos personales para ejecutar un contrato con usted. Por ejemplo, para procesar un pago suyo, tramitar su pedido o proporcionarle asistencia como cliente en relación con un pedido, o',
  privacyCont65:'el procesamiento es de nuestro interés legítimo y no se ve anulado por sus derechos, o',
  privacyCont66:'tenemos la obligación legal de recoger o revelar sus datos personales.',
  privacyCont67:'Uso de sus datos personales',
  privacyCont68:'STACEES utiliza sus datos personales para respaldar una variedad de actividades diferentes. Estas se enumeran en la siguiente tabla junto con los tipos de datos utilizados y las bases legales en las que nos basamos para procesarlos, incluidos, cuando corresponda, nuestros intereses legítimos. Tenga en cuenta que podemos procesar sus datos personales utilizando más de una base legal, según la actividad específica involucrada. Comuníquese con nosotros si necesita detalles sobre la base legal específica en la que nos basamos para procesar sus datos personales cuando se haya establecido más de una base legal en la siguiente tabla.',
  privacyCont69:'Propósito/Actividad',
  privacyCont70:'Tipo de datos',
  privacyCont71:'Base legítima para el tratamiento, incluidos los intereses legítimos',
  privacyCont72:'Para crear una cuenta y registrarle como nuevo cliente (directamente o a través de las redes sociales).',
  privacyCont73:'• Identidad',
  privacyCont74:'• Contacto',
  privacyCont75:'• Firmar un contrato con usted',
  privacyCont76:'Para procesar y entregar su pedido, incluyendo: registrar los detalles de su pedido; mantenerlo informado sobre el estado del pedido; procesar pagos y reembolsos, cobrar el dinero que nos debe; y ayudar a prevenir y detectar fraudes.',
  privacyCont77:'• Financiero',
  privacyCont78:'• Transacción',
  privacyCont79:'• Necesario para nuestros intereses legítimos (por ejemplo, para recuperar deudas que se nos deben)',
  privacyCont80:'Para gestionar nuestra relación con usted, lo que incluye: proporcionarle cualquier información, productos y servicios que nos solicite; notificarle cambios en nuestros servicios, términos y condiciones o aviso de privacidad; pedirle que deje una opinión o responda a una encuesta.',
  privacyCont81:'• Perfil',
  privacyCont82:'• Marketing y Comunicaciones',
  privacyCont83:'• Necesario para nuestros intereses legítimos (mantener actualizados nuestros registros y estudiar cómo utilizan los clientes nuestros productos y servicios).',
  privacyCont84:'Para permitirle participar en un concurso, evento, encuesta o recibir una recompensa por comprar con nosotros.',
  privacyCont85:'• Uso',
  privacyCont86:'• Necesario para nuestros intereses legítimos (para estudiar cómo utilizan los clientes nuestros productos y servicios, desarrollarlos y hacer crecer nuestro negocio)',
  privacyCont87:'• Si decide participar en un concurso o evento para la ejecución de un contrato con usted',
  privacyCont88:'Para administrar, proteger y mejorar nuestro negocio y nuestro sitio web/aplicación, incluyendo: resolución de problemas, análisis de datos, pruebas, mantenimiento del sistema, asistencia, análisis de datos, elaboración de informes y alojamiento de datos; configuración de opciones predeterminadas para usted, como el idioma y la moneda.  ',
  privacyCont89:'• Técnico',
  privacyCont90:'• Necesario para nuestros intereses legítimos (para administrar nuestro negocio, proporcionar servicios administrativos y de TI, seguridad de la red y detectar y prevenir fraudes)',
  privacyCont91:'• Necesario para cumplir con una obligación legal',
  privacyCont92:'Para ofrecerle contenidos pertinentes del sitio web, anuncios en línea e información; y medir la eficacia de la publicidad proporcionada.',
  privacyCont93:'• Necesario para nuestros intereses legítimos(para estudiar cómo utilizan los clientes nuestros productos y servicios, desarrollarlos, hacer crecer nuestro negocio y fundamentar nuestra estrategia de marketing)',
  privacyCont94:'Para utilizar el análisis de datos para : mejorar nuestro sitio web, productos, servicios, marketing, relaciones y experiencias de los clientes;',
  privacyCont29:'y para fines de investigación de mercado, estadísticos y de encuestas.',
  privacyCont95:'• Necesario para nuestros intereses legítimos(para definir tipos de clientes para nuestros productos y servicios, mantener nuestro sitio web actualizado y relevante, desarrollar nuestro negocio e informar nuestra estrategia de marketing)',
  privacyCont96:'Para recomendarle productos, servicios, descuentos y ofertas que puedan ser de su interés, incluido el envío de dicha información por correo electrónico, correo postal o SMS.',
  privacyCont97:'• Necesario para nuestros intereses legítimos(para desarrollar nuestros productos y servicios y hacer crecer nuestro negocio) o Consentimiento.',
  privacyCont98:"Ver más detalles en la sección 'Preferencias de Marketing, Publicidad y Cookies'",
  privacyCont99:'Para informarle o recordarle por correo electrónico cualquier tarea realizada a través de nuestro sitio web que quede sin completar, como pedidos incompletos o cestas abandonadas.',
  privacyCont100:'• Necesario para nuestros intereses legítimos (para mejorar la experiencia de compra de nuestros clientes)',
  privacyCont101:'Solo utilizaremos sus datos personales para los fines para los que los recogimos, a menos que consideremos razonablemente que necesitamos utilizarlos para otro motivo y que este es compatible con el fin original. Si deseamos utilizar sus datos personales para un fin no relacionado, se lo notificaremos y le explicaremos la base jurídica que nos permite hacerlo. Podemos tratar datos personales sin su consentimiento cuando así lo exija o permita la ley.',
  privacyCont102:'Si tiene alguna pregunta sobre cómo STACEES utiliza cualquiera de sus datos personales, contáctenos a service@stacees.com o chatee con nosotros.',
  privacyCont103:'Cuánto tiempo mantenemos sus datos',
  privacyCont104:'No conservaremos sus datos personales más tiempo del necesario para los fines para los que se facilitaron y para cumplir nuestras obligaciones legales. Si lo desea, puede solicitar información más detallada sobre los periodos de tiempo durante los que conservamos los datos.',
  privacyCont106:'Marketing - sus preferencias',
  privacyCont107:'Podemos enviarle comunicaciones de marketing y ofertas promocionales :',
  privacyCont108:'si ha abierto una cuenta con nosotros o nos ha comprado productos, o se ha inscrito en una promoción o evento, y no ha elegido no recibir ese marketing (de acuerdo con sus preferencias, como se explica más adelante);',
  privacyCont109:'por correo electrónico si se ha suscrito a los boletines informativos por correo electrónico ;',
  privacyCont110:'si nos ha proporcionado sus datos al participar en un concurso y ha dado su consentimiento para recibir este tipo de marketing (de acuerdo con sus preferencias, como se explica a continuación).',
  privacyCont111:'Podemos utilizar sus datos de Identidad, Contacto, Técnicos, Transaccionales, de Uso, de Perfil y de Marketing y Comunicaciones para formarnos una opinión sobre lo que creemos que puede gustarle, o lo que puede ser de su interés, y para enviarle detalles de productos y ofertas que pueden ser relevantes para usted.',
  privacyCont112:'Le preguntaremos sobre sus preferencias para recibir comunicaciones de marketing por correo electrónico, correo postal, SMS y otros canales de comunicación.',
  privacyCont113:'De vez en cuando, también podemos insertar en su pedido publicidad de bienes, servicios u ofertas de otras empresas de terceros que puedan ser de su interés.',
  privacyCont114:'Con respecto a las comunicaciones marketing de terceros, obtendremos su consentimiento expreso antes de compartir sus datos personales con terceros con fines de marketing.',
  privacyCont115:'Siempre tendrá pleno control sobre sus preferencias de marketing. Si no desea seguir recibiendo información comercial nuestra (o de terceros, en su caso) en cualquier momento :',
  privacyCont116:`Puede cancelar su suscripción o “excluirse” utilizando el botón para cancelar la suscripción y siguiendo el enlace incluido en el pie de página de cualquier correo electrónico de marketing; o <a class="font_red" href="{hrefLogin}">los titulares de cuentas pueden retirar su consentimiento simplemente iniciando sesión en mi cuenta y editando sus “Preferencias de contacto”.</a>`,
  privacyCont117:'Tramitaremos todas las solicitudes de exclusión lo antes posible, pero tenga en cuenta que, debido a la naturaleza de nuestros sistemas IT informáticos y servidores, pueden transcurrir unos días hasta que se ejecute cualquier solicitud de exclusión.',
  privacyCont118:'Cookies',
  privacyCont119:'Nuestro sitio web utiliza cookies para distinguirle de otros usuarios y hacer un seguimiento de sus visitas. Nos ayudan a ofrecerle la mejor experiencia cuando navega por nuestro sitio web y a introducir mejoras en el mismo. También nos ayudan a nosotros y a nuestras redes publicitarias a mostrarle publicidad relevante para usted y sus intereses.',
  privacyCont120:'Puede configurar su navegador para que rechace todas las cookies o algunas de ellas, o para que le avise cuando los sitios web instalen cookies o accedan a ellas. Si desactiva o rechaza las cookies, tenga en cuenta que algunas partes de nuestro sitio web pueden resultar inaccesibles o no funcionar correctamente.',
  privacyCont121:'Anuncios en línea',
  privacyCont122:'Utilizamos publicidad en línea para mantenerle informado de nuestras actividades y ayudarle a encontrar nuestros productos. Al igual que muchas empresas, podemos dirigirle banners y anuncios de STACEES cuando utilice otros sitios web y aplicaciones, basándonos en sus datos de contacto, técnicos, de uso y de perfil. Para ello, utilizamos diversas redes de marketing digital e intercambios publicitarios, así como una serie de tecnologías publicitarias, como balizas web, píxeles, etiquetas publicitarias, cookies e identificadores móviles. También usamos servicios específicos ofrecidos por algunos sitios y redes sociales, como el servicio de audiencia personalizada de Facebook.',
  privacyCont123:'Utilizamos Herramientas publicitarias de  analíticos y objetivo',
  privacyCont124:"Utilizamos herramientas publicitarias de  analíticos y objetivo para mostrarle contenido web relevante en nuestros sitios web y anuncios en línea en otros sitios web y aplicaciones (como se ha descrito anteriormente), para ofrecerle contenido relevante en las comunicaciones de marketing (cuando proceda) y para medir la eficacia de los anuncios publicados. Por ejemplo, utilizamos herramientas como Google Analytics para analizar los datos de la publicidad basada en intereses de Google y/o los datos de audiencia de terceros (por ejemplo, edad, estado civil, acontecimientos vitales, sexo e intereses) para orientar y mejorar nuestras campañas de marketing, estrategias de marketing y contenido del sitio web. También podemos utilizar herramientas proporcionadas por terceros, como Facebook, Adroll, Springbot, Criteo y Bing, para realizar tareas similares utilizando sus datos de contacto, técnicos, de uso y de perfil.",
  privacyCont125:'Para excluirse de la publicidad dirigida, debe deshabilitar sus "cookies" en la configuración de su navegador (consulte la Política de cookies para obtener más detalles) o cancelar la configuración de anuncios de terceros correspondientes. Por ejemplo, puede inhabilitar las funciones de publicidad gráfica de Google.',
  privacyCont126:'La Digital Advertising Alliance( que incluye a empresas como Google, Responsys y Facebook) ofrece una herramienta llamada WebChoices que le permite escanear rápidamente su ordenador o dispositivo móvil para averiguar qué empresas participantes han habilitado anuncios personalizados para su navegador y ajuste las preferencias de su navegador en consecuencia.',
  privacyCont127:'Si desea más información sobre los datos recogidos por estos terceros o sobre el modo en que se utilizan los datos, póngase en contáctenos.',
  privacyCont129:'Nuestro sitio web puede incluir enlaces hacia y desde los sitios web de nuestras redes asociadas, anunciantes y afiliados, o hacia plataformas de redes sociales. Si sigue un enlace a cualquiera de estos sitios web, tenga en cuenta que estos sitios web tienen sus propias políticas de privacidad y que no aceptamos ninguna responsabilidad por estas políticas. Consulte estas políticas antes de enviar cualquier dato personal a sus sitios web.',
  privacyCont131:'Podemos revelar y compartir sus datos personales con las partes que se indican a continuación :',
  privacyCont132:'cuando nos haya dado su consentimiento para ello. Por ejemplo, si ha dado su consentimiento para recibir material de marketing de terceros, o en relación con productos y servicios de terceros (incluidos los de marca compartida o promocionados conjuntamente), podremos transmitir sus datos a los terceros pertinentes con el fin de enviarle dichas comunicaciones comerciales;',
  privacyCont133:'Contratamos a proveedores de servicios externos para suministrar sistemas IT y programas informáticos y alojar nuestros sitios web;',
  privacyCont134:'servicios de procesamiento de pagos de terceros (incluidos Authorize.net, PayPal, Braintree y Card Connect) para procesar su pago a nosotros. No almacenamos los datos de su tarjeta de crédito/débito;',
  privacyCont135:'proveedores de servicios externos que contratamos para entregar los bienes que usted ha pedido;',
  privacyCont136:'proveedores de servicios externos que contratamos para enviar correos electrónicos y correo postal en nuestro nombre, incluso en relación con pedidos incompletos o cestas abandonadas, o comunicaciones de marketing, para proporcionar servicios de limpieza de datos y para proporcionar servicios de marketing y publicidad;',
  privacyCont137:'proveedores de análisis y motores de búsqueda que nos ayudan a mejorar y optimizar nuestro sitio web;',
  privacyCont138:'está visitando una red de filiales de nuestro sitio web;',
  privacyCont139:'a cualquier tercero al que podamos optar por vender, transferir o fusionar partes de nuestro negocio o nuestros activos. Alternativamente, podemos intentar adquirir otros negocios o fusionarnos con ellos. Si se produce un cambio en nuestro negocio, los nuevos propietarios pueden utilizar sus datos personales de la misma manera que se establece en este aviso de privacidad.',
  privacyCont140:'Para proteger a nuestros clientes y nuestro sitio web contra el fraude y el robo, podemos compartir los datos personales necesarios para realizar comprobaciones de identidad y los datos personales que obtenemos al realizar comprobaciones de identidad (incluidos los datos relativos a su edad, nombre y ubicación), junto con la información de la cuenta, con organizaciones (incluidos los organismos encargados de hacer cumplir la ley), que participan en la prevención y detección del fraude y la reducción del riesgo crediticio. Tenga en cuenta que estos terceros pueden conservar un registro de la información que les proporcionamos con este fin;',
  privacyCont141:'Si tenemos la obligación de revelar o compartir sus datos personales para cumplir alguna obligación legal;o',
  privacyCont142:'a nuestros asesores profesionales, incluidos abogados, banqueros, auditores y aseguradoras que prestan servicios de consultoría, banca, jurídicos, de seguros y contabilidad. ',
  privacyCont143:'Sus datos y los países fuera de Europa',
  privacyCont144:'Los datos personales que recopilamos de usted pueden transferirse legalmente a través de las fronteras utilizando los mecanismos previstos por la ley y a destinos fuera del Espacio Económico Europeo ("EEE") y almacenarse en ellos.También puede ser tratada por empleados que trabajen para nosotros o para uno de nuestros proveedores fuera del EEE. Dicho personal puede participar, entre otras cosas, en la ejecución de su pedido, el tratamiento de sus datos de pago y la prestación de servicios de asistencia. Tomaremos todas las medidas necesarias para garantizar que sus datos se traten de forma segura y conforme al presente aviso de privacidad.',
  privacyCont145:'Siempre que transfiramos datos personales fuera del EEE, garantizaremos un grado similar de protección de los mismos, asegurándonos de que existen las salvaguardas adecuadas, tal y como exige la ley. Esto puede incluir el uso de cláusulas contractuales específicas aprobadas por la Comisión Europea que otorgan a los datos personales la misma protección que tienen en Europa. Puede obtener más información al respecto aquí  http://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX:32010D0087',
  privacyCont146:'Póngase en contacto con nosotros si desea obtener más información sobre los países a los que podemos transferir datos personales y el mecanismo específico que utilizamos al transferir sus datos personales fuera del EEE.',
  privacyCont148:'Usted tiene varios derechos en virtud de la legislación sobre privacidad de datos. Esto incluye, en determinadas circunstancias, el derecho a :',
  privacyCont155:'Contra la toma de decisiones automatizada',
  privacyCont156:`A continuación  se describen  brevemente cada uno de estos derechos  detalle.Si desea ejercer alguno de estos derechos, envíenos un correo electrónico a privacy@Stacees.com o chatee con nosotros.`,
  privacyCont157:'Solicitar acceso a sus datos personales',
  privacyCont158:'Tiene derecho a obtener una copia de los datos personales que tenemos sobre usted y cierta información relacionada con nuestro procesamiento de sus datos personales.',
  privacyCont159:'Solicitar la corrección de sus datos personales',
  privacyCont160:'Tiene derecho a que se corrijan sus datos personales si son inexactos o están incompletos. Puede actualizar sus datos personales en cualquier momento accediendo a su cuenta y actualizándolos directamente, o enviándonos un correo electrónico a privacy@stacees.com o chateando con nosotros.',
  privacyCont161:'Solicitar la supresión de sus datos personales',
  privacyCont162:'Esto le permite solicitar que STACEES elimine sus datos personales, cuando no exista una buena razón para que sigamos procesándolos. Sin embargo, tenga en cuenta que es posible que no siempre podamos cumplir con su solicitud de eliminación por motivos legales específicos que se le notificarán, si corresponde, en el momento de su solicitud.',
  privacyCont163:'Solicitar la restricción del tratamiento de sus datos personales',
  privacyCont164:'Usted tiene derecho a solicitar a STACEES que suspenda el tratamiento de sus datos personales en determinados supuestos, por ejemplo, si desea que establezcamos la exactitud de los datos, o si se ha opuesto a que utilicemos sus datos, pero necesitamos verificar si tenemos motivos legítimos imperiosos para utilizarlos. Cuando el tratamiento está restringido, podemos conservar información suficiente sobre usted para garantizar que la restricción se respeta en el futuro.',
  privacyCont165:'Solicitar la transmisión de sus datos personales',
  privacyCont166:'Tiene derecho a obtener una copia digital de sus datos personales o a solicitar que se transmitan a otra empresa.  Tenga en cuenta que este derecho solo se aplica a los datos automatizados que usted haya aceptado inicialmente que utilicemos, o cuando hayamos utilizado estos datos para celebrar un contrato con usted.',
  privacyCont167:'Contra del tratamiento de sus datos personales',
  privacyCont168:'Tiene derecho a oponerse al tratamiento de sus datos personales cuando consideremos que tenemos un interés legítimo en dicho tratamiento (como se ha explicado anteriormente). También tiene derecho a oponerse a que procesemos sus datos personales con fines de marketing directo. En algunos casos, podemos demostrar que tenemos motivos legítimos imperiosos para tratar sus datos que prevalecen sobre sus derechos y libertades.',
  privacyCont169:'Contra la toma de decisiones y el análisis automatizados',
  privacyCont170:'Tiene derecho a oponerse al tratamiento automatizado de sus datos personales sin intervención humana. Este tipo de tratamiento está permitido como parte de nuestro contrato con usted, siempre que se hayan tomado las medidas de protección adecuadas o que hayamos obtenido su consentimiento expreso.',
  privacyCont171:'Intentaremos responder a todas las solicitudes legítimas en el plazo de un mes. No obstante, ocasionalmente podemos tardar más de un mes si su solicitud es especialmente compleja o si ha realizado varias solicitudes. En tal caso, se lo notificaremos y le mantendremos informado. Es posible que necesitemos solicitarle información específica para confirmar su identidad y garantizar su derecho a ejercer cualquiera de los derechos mencionados. Se trata de una medida de seguridad para garantizar la confidencialidad de los datos personales y evitar que se revelen a personas que no tengan derecho a recibirlos.',
  privacyCont172:'Derecho a presentar una denuncia',
  privacyCont173:`Si tiene alguna duda o queja sobre la forma en que procesamos sus datos, envíenos un correo electrónico directamente a service@stacees.com o chatee con nosotros. También tiene derecho a presentar una reclamación ante la OIC (organismo de control de la protección de datos en el Reino Unido). Sin embargo, le agradeceríamos que nos diera la oportunidad de resolver sus dudas antes de ponerse en contacto con la OIC, por lo que le rogamos que se ponga en contacto con nosotros en primera instancia.`,
  privacyCont175:'De vez en cuando podemos cambiar este aviso de privacidad. Si se produce algún cambio significativo, publicaremos las actualizaciones en nuestro sitio web y aplicaciones, o se lo comunicaremos por correo electrónico.',
  privacyCont176:`Agradecemos sus comentarios y estaremos encantados de responder a cualquier pregunta que pueda tener sobre sus datos. Envíe cualquier pregunta, comentario o solicitud de más información a service@stacees.com o chatee con nosotros. Este Aviso de privacidad se actualizó por última vez el 22 de septiembre de 2022.`,
  //mix-match-bridesmaid
  mixMatchTit:'Vestidos de dama de honor en colores y estilos Mix & Match',
  maxBrid:'VESTIDOS DE DAMA DE HONORMIX & MATCH',
  maxBrid1:'CÓMO:  DAMAS DE HONOR MIX & MATCH',
  maxBrid2:"<p>A la hora de elegir el atuendo para la fiesta de boda, puede parecer que todo el, mundo tiene una </p><p>opinión.Si quieres complacer a toda la tripulación (a la vez que te ves muy elegante), considera los </p><p>vestidos de dama de honor desparejados.Cuando se ejecuta correctamente, vestidos de dama de </p><p>honor no coincidentes ayudarán a su equipo se destacan, Aquí, cubrimos todo lo que necesita saber </p><p>acerca de la coordinación impecable vestidos de dama de honor no coincidentes.Además, hemos incluido una guía de compra con los colores más populares.</p>",
  maxBrid3:'comprar vestidos de dama de honor',
  maxBrid4:'Estilo diferente (¡Mismo color!)',
  maxBrid5:'El tipo más común de fiesta nupcial mix and match presenta diferentes estilos de vestidos pero del mismo color. Es perfecto cuando te enamoras de un color de boda, pero quieres el popular look desparejado. Esta opción es la más fácil de coordinar y permitirá a tus damas de honor tener una opción. Permitir que tus damas de honor elijan su propio estilo hará que se sientan más cómodas y seguras.Después de todo, ¡los diferentes estilos favorecen a diferentes tipos de cuerpo!',
  maxBrid6:`comprar por color`,
  maxBrid7:'Diferente color (¡Mismo estilo!)',
  maxBrid8:'Cuando no puedes elegir un solo color, ¿por qué no elegir varios? Esta opción es más fácil de coordinar que una mezcla de diferentes estilos y colores de vestidos, ya que no hay riesgo de que los diseños desentonen. Simplemente elige un estilo de vestido que favorezca a todas las damas de honor de tu séquito. Por lo general, una silueta en forma de A funciona mejor. A partir de ahí, especifica los colores que pueden usar, ¡pero asegúrate de que haya una distribución uniforme! No quieres que todas menos una de las damas de honor combinen. Recomendamos que la novia especifique qué color debe usar cada persona del grupo.',
  maxBrid9:'Compra este estilo',
  maxBrid10:'Vestidos de diferentes colores y estilos',
  maxBrid11:'Entonces, quieres que tus niñas usen vestidos de dama de honor en diferentes estilos y colores. ¡No te preocupes! Si bien esta opción requiere más trabajo, también brindará el aspecto más exclusivo. Primero, deberás determinar qué colores planeas usar. Recomendamos de 3 a 4 y asegurarte de que se complementen entre sí. Por ejemplo, mezcla tonos y matices de un color, como azul, rosa o violeta. A continuación, debes seleccionar el estilo de los vestidos. Si bien quieres que los vestidos favorezcan a cada una de tus niñas, cada vestido también debe complementarse entre sí. Así que intenta que las siluetas, las telas o los detalles coincidan. De lo contrario, terminarás con una alineación que se verá demasiado recargada o caótica. La mejor opción es presentar siluetas, cinturas, escotes o tirantes similares.',
  maxBrid12:'Consejos para coordinar vestidos de dama de honor desparejados ',
  maxBrid13:'Incluso si tu objetivo es una fiesta nupcial con damas de honor desparejadas, quieres que parezca bien pensada, no desordenada. Sigue estos consejos para que tus damas de honor parezcan intencionadas y cuidadosamente planificada.',
  maxBrid14:'Ata tus vestidos de dama de honor con un detalle común',
  maxBrid15:"<p>Elegir vestidos que tengan detalles comunes es una de </p><p>las mejores formas de garantizar un look cohesivo. </p><p>Puede tratarse de telas a juego (gasa, tul, terciopelo), </p><p>escotes similares, colores a juego, siluetas similares, etc.  </p><p>Necesitas al menos uno o dos detalles que combinen, </p><p>details that match, otherwise, you risk a chaotic vibe </p><p>caótico que no sea agradable a la vista.</p>",
  maxBrid16:"<p>Siempre recomendamos usar vestidos del mismo largo. </p><p>¡Combinar vestidos cortos y largos rara vez funciona! </p><p>Especialmente si tenemos en cuenta que cada largo </p><p>aporta un estilo diferente. Los vestidos largos suelen </p><p>considerarse más formales, mientras que los cortos son </p><p>un poco más informales. No queremos que nuestras </p><p>chicas parezcan confundidas con la ocasión.</p>",
  maxBrid17:'Piensa bien en la paleta de colores de tu boda',
  maxBrid18:`<p>Al seleccionar los colores que usarán tus damas de </p><p>honor, no te limites a combinar una paleta de colores. </p><p>Algunos colores no se mezclan, como los tonos cálidos </p><p>y fríos. Busca fotos de inspiración y <a href="{hrefFabric}" class="color_pink">pide muestras</a> para </p><p>probar cómo se verán los colores del vestido uno al lado </p><p>del otro. Una vez que hayas encontrado la paleta de </p><p>colores perfecta para tu boda, es más fácil que nunca </p><p>comprar tus vestidos de dama de honor en línea en STACEES.</p>`,  
  maxBrid19:'¡Dale a tus damas de honor amplia orientación!',
  maxBrid20:"<p>Es importante que, al permitir que tus damas de honor </p><p>mezclen y combinen colores y/o estilos, les des una </p><p>orientación. Debes crear un tablero de inspiración o </p><p>fotos de muestra que puedas compartir y que les den </p><p>una idea del estilo que buscas. No esperes que tus </p><p>damas de honor encuentren un vestido (que te </p><p>encante) por sí mismas. Tú eres responsable de </p><p>coordinar todo. Una de las mejores formas de ayudarlas </p><p>es recomendarles tus sitios web favoritos.</p>",
  maxBrid21:'Beneficios de los vestidos de dama de honor desparejados',
  maxBrid22:'Los vestidos de dama de honor desparejados tienen muchas ventajas. La primera, como ya hemos mencionado, es que tus chicas sabrán qué estilos favorecen más a sus cuerpos. También podrán vestirse según su estilo personal, ya sea modesto o más sexy. Así se sentirán cómodas y seguras de sí mismas. Además, y esto es igual de importante, les permitirá cierta flexibilidad en cuanto al precio. No todo el mundo puede permitirse el mismo vestido. Permitir que tus hijas elijan su propio vestido significa que pueden ajustarse a su presupuesto, sin culpas ni presiones.',
  maxBrid23:'Sabemos que crear una fiesta nupcial dispareja es más fácil de decir que de hacer. Para inspirarte, echa un vistazo a estas bodas reales en Kennedy Blue para ver los colores y estilos que puedes incorporar a tu boda.',
  maxBrid24:'Conclusión',
  maxBrid25:"Ahora te damos algunas sugerencias serias para que las tengas en cuenta. ¡Es hora de tomar una decisión! ¿Prefieres un vestido de dama de honor desigual o del mismo estilo? Ponte en contacto con nosotros por correo electrónico:",
  maxBrid26:" , dinos qué piensas y estaremos encantados de ayudarte.",
  maxBrid27:`Compra por esta mezcla de colores`,
  shopBy:'Comprar por colores',
  shopByTxt1:`Encuentra colores que complementen la paleta de tu boda o mézclalos y combínalos.`,
  shopByTxt2:`Hola chica, ¿estás ocupada comprando vestidos de dama de honor por colores? Echa un vistazo a la colección de vestidos de dama de honor de STACEES. Como fabricante profesional de vestidos desde hace más de una década, STACEES ofrece a las mujeres vestidos de primera calidad con un diseño fabuloso y detalles impecables. No solo disponemos de modelos de todas las tallas, sino también de una variedad inimaginable de colores.Disponemos de los colores habituales para los vestidos de dama de honor, como el rosa o el morado. Sin embargo, también ofrecemos a nuestros clientes una selecta muestra de colores para que sus chicas puedan destacar entre todas.Entendemos la dificultad de echar un vistazo a cientos de tiendas de vestidos de dama de honor en línea para encontrar solo uno o dos diseños. Para ser sinceros, no hay muchas tiendas que ofrezcan vestidos de dama de honor que garanticen tanto el aspecto como la calidad.Aunque estés satisfecha con el diseño y todo lo demás, luego te sentirás intimidada por el precio increíblemente alto. STACEES es una de las mejores tiendas de vestidos de dama de honor y está decidida a atender a todas las futuras novias. Así, no hay necesidad de angustiarse por nada nupcial.Lo único que tienes que hacer es elegir un par de vestidos de dama de honor de colores elegantes para tus amigas y familiares y dejarnos el resto a nosotros. Somos profesionales. Y nunca defraudamos a nuestros clientes.`,
  shopBridDress:'Comprar todos los vestidos de dama de honor {color}',
  colorSwatch:`Muestras de color`,
  shopNow:'Comprar',
  allPinks:'Todos los rosas',
  allBlues:'Todos los azules',
  allYellows:'Todos los amarillos',
  allGreens:'Todos los verdes',
  allOranges:'Todos los naranjas',
  allPurples:'Todos los púrpuras',
  allReds:'Todos los rojos',
  allGreys:'Todos los grises',
  //most loved
  mostLovedTit:'Vestidos de novia favoritos',
  mostLoved1:'Clásico Vintage',
  mostLoved2:"Vestidos de novia vintage para un look <span>atemporal que siempre estará de moda</span>",
  mostLoved3:'Boho romántico',
  mostLoved4:'Estos diseños son sexys y sofisticados con <span>encaje elegante y espalda abierta.</span>',
  mostLoved5:'Glamour moderno',
  mostLoved6:'Los vestidos de novia glamorosos a menudo <span>tienen algunos detalles asombrosos. </span>',
  mostLoved7:`Nuestros colores están cuidadosamente seleccionados para que mezclarlos y combinarlos sea muy fácil.`,
  mostLoved8:'Ver más estilos', 
  mostLoved9:'Damas de honor <span>Mix & Match</span>',
  //Order Cancellation
  orderTit:'Cancelación de pedido',
  orderCan1:'Su pedido comienza a procesarse inmediatamente después de pedido. Entendemos que encargar un vestido para su ocasión especial es una empresa importante,',
  orderCan2:'y nuestra política de cancelación se creó teniendo esto en cuenta. Sin embargo, es importante tener en cuenta que nuestros vestidos se confeccionan para cada pedido de forma individual.',
  orderCan3:'y una vez iniciado el proceso de creación los materiales no se pueden reutilizar. Durante el tiempo de procesamiento del pedido,',
  orderCan4:'es posible cancelarlo. Sin embargo, una vez que el pedido pasa al proceso de envío, no se puede cancelar.',
  orderCan24:'En el improbable caso de que desee cancelar su pedido, puede',
  orderCan5:'Los pedidos cancelados dentro de las 12 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 90% del precio de compra del producto.',
  orderCan6:'Los pedidos cancelados dentro de las 12 a 24 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial consistente en el costo total de envío y el 80% del precio de compra del producto.',
  orderCan7:'Los pedidos cancelados dentro de las 24 a 48 horas posteriores a la confirmación del pago serán elegibles para un reembolso parcial que consiste en el costo total de envío y el 50% del precio de compra del producto.',
  orderCan8:'Más de 48 horas',
  orderCan9:'Los pedidos cancelados más de 48 horas después de la confirmación del pago serán elegibles para un reembolso parcial únicamente del costo total de envío.',
  orderCan10:'Una vez que su pedido haya sido enviado, ya no podrá cancelarse.',
  orderCan11:'Si desea cancelar su pedido, inicie sesión en <a class="udline" href="{hrefOrder}">mis pedidos</a>, seleccione el pedido con el que necesita ayuda y solicite la cancelación. O haga clic <a class="udline" href="{hrefContact}">en contáctenos</a> to proceed.',
  orderCan12:'Acción',
  orderCan13:"Time Frames",
  orderCan14:'Importe reembolsado',
  orderCan16:'Gastos de envío',
  orderCan17:'Cancelación',
  orderCan18:'Dentro de las 12 horas de la confirmación del pedido',
  orderCan19:'Dentro de las 12-24 horas de la confirmación del pedido',
  orderCan20:'Dentro de las 24-48  horas de la confirmación del pedido',
  orderCan21:'Más de 48 horas, no se envía',
  orderCan22:'ninguno',
  orderCan23:'Pedido enviado',
  //success
  check:'Tramitar pedido',
  checkoutWait1: 'Por favor espere un momento',
  successInfo1: '¡Pedido recibido!',
  successInfo2: 'Por favor revise su correo electrónico dentro de 30 minutos.',
  successInfo3: "Por favor, revise y responda el correo electrónico de confirmación para asegurarse de que la información sobre su pedido sea correcta. El correo electrónico de confirmación se le enviará dentro de los 30 minutos. Si nunca recibe el correo electrónico de confirmación, revise el correo no deseado o contáctenos a través de",
  backHome: 'Volver a inicio',
  pageNotFond: 'página no encuentra',
  //title
  logInTit: 'Identificarse o crear una cuenta',
  myOrderTit: 'Mis pedidos en STACEES',
  myCouponsTit: 'Mis cupones en STACEES',
  myAccTit: 'Mi cuenta en STACEES',
  myProfileTit: 'Mi perfil en STACEES',
  myAddressTit: "Directorio en STACEES",
  myTicketTit: 'Consultas Postventa en STACEES',
  myPasswdTit: 'Mi contraseña en STACEES',
  myReviewTit: 'Mis comentarios en STACEES', 
  createTicketTit:'Crear un ticket en STACEES',
  shopColorTit:'Comprar vestidos de dama de honor por color',
  shopColorKword:'colores de vestidos de dama de honor, vestidos de dama de honor azul polvoriento, vestidos de dama de honor rosa polvoriento, vestidos de dama de honor color borgoña, vestidos de dama de honor azules, vestidos de dama de honor rosas, vestidos de dama de honor verde esmeralda, vestidos de dama de honor rojos, vestidos de dama de honor púrpura, vestidos de dama de honor amarillos, colores de vestidos de dama de honor, blanco, negro, vestidos de dama de honor por color, vestidos de dama de honor oro, vestidos de dama de honor champán',
  shopColorDesc:'Comprar vestidos de dama de honor y batas de dama de honor por color. Encuentra el vestido de dama de honor perfecto en más de 75 colores.To match your wedding color palette.Combina la paleta de colores de tu boda. Puede obtener muestras de colores de telas y ver los colores en persona para obtener ejemplos del mundo real sobre cómo coordinar los colores de una boda.',
  homeTit:'Vestidos y accesorios de moda de STACEES para ocasiones especiales | Vestidos de dama de honor | Vestidos de boda | Vestidos de graduación | Vestidos de noche y más en stacees.com',
  homeKeyword:'Vestidos de boda, vestidos de novia, vestidos de fiesta, vestidos de noche, vestidos de dama de honor,  vestidos para ocasiones especiales de tienda',
  homeDesc:'Ofrecemos vestidos de novia, vestidos de noche, vestidos de graduación, vestidos de fiesta, vestidos de cóctel, vestidos de dama de honor y vestidos de pajecitas en nuestra tienda en línea.¡Grandes descuentos en accesorios de boda a la venta! En nuestro sitio web encontrará una gran variedad de vestidos de gran venta a precios razonables.',
  styleGalleryDesc:'Grandes colecciones de vestidos de novia, vestidos de dama de honor, vestidos de graduación, vestidos para homecoming, zapatos y más en STACEES.¡Mira nuestra galería de estilos y encuentra el vestido de tus sueños aquí!',
  studentbeans:'Studentbeans',
  searchTit1:'Comprar todo {name}',
  searchTit2:'Único {name}',
  searchTit3:'Envío y devoluciones gratis. {name}',
  searchTit4:'Venta caliente {name}',
  searchTit5:'Varios {name} Convertirse en alta costura',
  searchTit6:'Todo sobre mi {name}',
  searchTit7:'Varios impresionantes {name} Ideas',
  searchTit8:'Inesperado {name} Diseño',
  searchTit9:'El mejor lugar para comprar disponible {name}',
  searchTit10:'{name} En línea ',
  searchTit11:'Compra lo mejor {name} en línea',
  searchDesc1:`Proporcionar excelente {name} en STACEES con estilos y tamaños inesperados. Haz que el cumpleaños de tu hija sea muy especial con el outfit perfecto.`,
  searchDesc2:`Compra {name} en STACEES.Elige el look perfecto para tu día con estas opciones exquisitas y fluidas.`,
  searchDesc3:`Compra nuestra increíble colección {name}en línea en una variedad de colores, diseños, tamaños y estilos.`,
  searchDesc4:`¡Tienes suerte! Ahora compra impresionantes {name} , y viste a tu madre con STACEES, disponibles en diferentes colores y estilos.`,
  searchDesc5:`Mira lo que vistieron esas mujeres con estilo cuando se casaron sus hijos . {name} tiene un papel importante en cada boda.`,
  searchDesc6:`STACEES proporciona {name} que puede adaptarse a cualquier estilo o tamaño.`,
  searchDesc7:`Como ocurre con la moda nupcial, {name} no tiene muchas reglas. ¡Vamos! Compra venta caliente {name} en STACEES.`,
  searchDesc8:`Descubre lo único {name} de STACEES y sé el invitado perfecto en ese día tan especial.`,
  mixKeyWord:'mezclar y combinar vestidos de dama de honor,la dama de honor se separa,vestido de dama de honor convertible,vestidos de dama de honor desparejados,vestidos de dama de honor del mismo color en diferentes estilos',
  mixDesc:'¿Cómo mezclar y combinar vestidos de dama de honor con colores, tejidos y estilos?¡Eche una mirada única a nuestra colección de vestidos y conjuntos de dama de honor y aprenda a crear tonos de boda sombríos para darle a su vestido de novia un estilo, color o tono diferente!',
  orderDetailTit:'Ver detalles en STACEES',
  paymentTit: 'Pago exito',
  emailReceive:'¿Por favor ayúdanos a saber qué correos te gustaría recibir?',
  subscribe:'suscripción',
  //accessoires
  accessInfo: 'Joyas y accesorios modernos y ultra femeninos para tu gran día y mucho después.',
  wrapShawls: 'Chaquetas y chales',
  bridalGloves: 'Guantes de novia',
  hats: 'Sombreros',
  handbags: 'Bolsos de mano',
  fabricSwatch: 'Muestra De Fábrica',
  hairAccess: 'Accesorios para el cabello',
  sashesBelts: 'Fajas & cinturones',
  weddJewelery: 'Joyas de boda & fiesta',
  weddFlowers: 'Flores de boda',
  weddPett: 'Enaguas de boda',
  accessTit: 'Tendencias y estilos de accesorios para bodas y ocasiones especiales',
  accessKeyWd:'Accesorios para bodas, accesorios para ocasiones especiales',
  accessDesc:'Si busca inspiración en accesorios para un próximo evento o boda, compre las mejores tendencias en accesorios de moda, los estilos más populares y los estilos más nuevos de velos, tocados, bolsos de mano, alfileres, peines, joyas y más en STACEES para combinar con su vestido ahora. Alta calidad y envío rápido',
  ok:'Ok',
}