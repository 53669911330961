
const state = {
    useInfo:{
        name:"张三"
    }
}
const mutations = {

}
const actions = {

}
export default {
    namespaced:true,
    state,
    mutations,
    actions
}