
const state = {
    useInfo:{
        name:"张三"
    }
}
const mutations = {
    setChangeName:(state,value)=>{
        return state.useInfo.name = value
    }
}
const actions = {
    getChangeName({commit},params){
        commit('setChangeName',params)
    }
}
export default {
    namespaced:true,
    state,
    mutations,
    actions
}