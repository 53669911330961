<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="less">
@import "./assets/iconfont/iconfont.css";
@import "./assets/css/variable.less";
@import "./assets/css/common.less";
@import "./assets/css/base.css";
@import "./assets/css/size.less";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
