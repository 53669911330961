import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import count from './modules/count'
import getters from './getters'

Vue.use(Vuex)
 
export default new Vuex.Store({
    modules:{
        count,
        user
    },
    getters
    // ...count
})