import Vue from 'vue'
import VueRouter from 'vue-router'

const lang = window.location.pathname.split('/')[1];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
let routerUrl = ['about-us','shipping-info','faq','returns-refund','order-cancellation','project-tailor-made','size-chart','color-chart','contact-us','privacy-policy','terms-conditions','payment-methods','size/dress','size/flower-girl-dress','size/jewelry','size/lingerie','size/pajamas','size/rings','size/shoes','size/wrap','paypal','checkout-payment','checkout-payment/pay-button','ticket']
let routes = [
    {
        path: '/',
        component: () => import('@/page/index'),
        meta: {title:"STACEES"},
        hidden: true
    },
];
routerUrl.map((component,i)=>{
    routes.push({
        path:lang == component.split('/')[0]? `/${component}`:`/${lang}/${component}`,
        component: () => import(`@/page/${component}`),
        meta: {
            title:component.split('-').join(' '),
        }
    })
});

const router = new VueRouter({
    mode: 'history', //去掉url中的#
    base: '/',
    routes
})

export default router
